import { PropsWithChildren, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { useGetFinishDataQuery } from "features/globalsApi.ts";

interface GlobalFormProviderProps extends PropsWithChildren<{}> {
	isUpdate?: boolean;
}

const GlobalFormProvider = ({ children, isUpdate }: GlobalFormProviderProps) => {
	const [finishId, setFinishId] = useState<string>("");
	const { data: finishData } = useGetFinishDataQuery(finishId, { skip: !finishId });

	const formMethods = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		context: {
			hasPackaging: Boolean(finishData?.packagings?.length !== 0),
			isUpdate: isUpdate
		}
	});

	const selectedFinish = formMethods.watch("finish");

	useEffect(() => {
		if (selectedFinish?.id) {
			setFinishId(selectedFinish.id);
		}
	}, [selectedFinish?.id]);

	return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export default GlobalFormProvider;
