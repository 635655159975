export const ProductSelectFormError = "Please select a product";

export const AddLineItemHeader = "Add Item";

export const AddLineItemRequired = "Required Field(*)";

export const AddLineItemProductPlaceholder = "Enter item or item description";

export const AddLineItemStandardDimensionsHeader = "Standard dimensions";

export const AddLineItemQuantityPlaceholder = "Qty";

export const AddLineDepthLabel = "Depth:";

export const AddLineHeightLabel = "Height:";

export const AddLineWidthLabel = "Width:";

export const AddLineCancelButton = "CANCEL";

export const AddLineSaveButton = "Save";

export const AddLineAddAnotherItemButton = "Add another item";

export const AddLineEnterText = "enter to add item";

export const AddLineAnotherItemText = "shift + enter to add another item";

export const AddLineAddLineButtonText = "Add item";
export const AddLineSaveTextOne = "enter to";
export const AddLineSaveTextTwo = "add item";
export const AddLineAddAnotherTextOne = "shift + enter to";
export const AddLineAddAnotherTextTwo = "add another item";
