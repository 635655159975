import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "../data/api/v1/endpoints";
import { baseQueryWithRedirect } from "./apiUtils";
import { ProductLineViewModel } from "../data/api/v1/model/product-line-view-model";
import { StyleViewModel } from "data/api/v1/model/style-view-model";
import { FinishViewModel } from "data/api/v1/model/finish-view-model";

export const globalsApiSlice = createApi({
	reducerPath: "globalsApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getProductLines: builder.query<ProductLineViewModel[], void>({
			query: () => endpoints.globals.getProductLines(),
			transformResponse: (response: { data: ProductLineViewModel[] }) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		getProductLine: builder.query<ProductLineViewModel, string>({
			query: (productLineId) => endpoints.globals.getProductLine(productLineId),
			transformResponse: (response: { data: ProductLineViewModel }) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		getStyleData: builder.query<StyleViewModel, string>({
			query: (styleId: string) => endpoints.globals.getStyle(styleId),
			transformResponse: (response: { data: StyleViewModel }) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		getFinishData: builder.query<FinishViewModel, string>({
			query: (finishId: string) => endpoints.globals.getFinish(finishId),
			transformResponse: (response: { data: FinishViewModel }) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		})
	})
});

export const {
	useGetProductLinesQuery,
	useLazyGetProductLineQuery,
	useLazyGetStyleDataQuery,
	useGetStyleDataQuery,
	useLazyGetFinishDataQuery,
	useGetFinishDataQuery
} = globalsApiSlice;
