import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "../data/api/v1/endpoints";

import { APIErrorResponse, baseQueryWithRedirect, UntransformedResponse } from "./apiUtils";
import { AddressSuggestionViewModel } from "data/api/v1/model/address-suggestion-view-model";
import type {
	AddressValidateResponse,
	AddressValidateRequestBody
} from "data/api/v1/model/address-validate-view-model.ts";

export const addressApiSlice = createApi({
	reducerPath: "addressApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getAddressSuggestions: builder.query<AddressSuggestionViewModel[], string>({
			query: (address: string) => endpoints.addresses.getSuggestions(address),
			transformResponse: (response: { data: AddressSuggestionViewModel[] }) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		validateAddress: builder.mutation<AddressValidateResponse, AddressValidateRequestBody>({
			query: (address: AddressValidateRequestBody) => ({
				url: endpoints.addresses.validateAddress(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: address
			}),
			transformResponse: (response: UntransformedResponse<AddressValidateResponse>) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				status: error?.status
			})
		})
	})
});

export const { useLazyGetAddressSuggestionsQuery, useValidateAddressMutation } = addressApiSlice;
