import { type SyntheticEvent, useEffect, useReducer, useState } from "react";
import { DialogTitle, FooterActions } from "components/Dialog";
import {
	AutocompleteOption,
	BottomSection,
	Content,
	Divider,
	FieldGroup,
	RequiredFieldsText,
	SplitRow,
	StyledDialog,
	VerifyAddressContent
} from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles";
import { useFormContext } from "react-hook-form";
import { AddressFormFields, FULL_NAME_MAX_LENGTH, schema } from "pages/Replacements/forms/AddressForm/schema";
import TextField from "@mui/material/TextField";
import { getFormFieldProps } from "utils/form";
import {
	AddNewAddressTitle,
	Address2Label,
	AddressHelperText,
	AddressLabel,
	CityLabel,
	ContactInformationNotice,
	ContactInformationTitle,
	CountyLabel,
	EditAddressButton,
	EmailLabel,
	FullNameHelperText,
	FullNameLabel,
	PhoneLabel,
	RequiredFieldNotice,
	SaveAndContinueButton,
	StateLabel,
	ZipLabel
} from "constants/text";
import Autocomplete from "@mui/material/Autocomplete";
import ListboxComponent from "pages/Replacements/components/AddNewAddressModal/components/ListboxComponent";
import { addressApiSlice, useLazyGetAddressSuggestionsQuery, useValidateAddressMutation } from "features/addressApi";
import useDebounce from "hooks/useDebounce";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { states } from "constants/states";
import { AddressSuggestionViewModel } from "data/api/v1/model/address-suggestion-view-model";
import MaskedPhoneTextField from "pages/Replacements/components/AddNewAddressModal/components/MaskedPhoneTextField";
import HelperText from "components/Form/HelperText/HelperText";
import { useDispatch } from "react-redux";
import ConfirmAddress, {
	RADIO_BUTTON_VALUES
} from "pages/Replacements/components/AddNewAddressModal/components/ConfirmAddress.tsx";

const MODAL_STATE = {
	edit: "edit",
	confirm: "confirm"
} as const;

const reducer = (state: keyof typeof MODAL_STATE, action: { type: keyof typeof MODAL_STATE }) => {
	switch (action.type) {
		case MODAL_STATE.edit:
			return MODAL_STATE.edit;
		case MODAL_STATE.confirm:
			return MODAL_STATE.confirm;
		default:
			return state;
	}
};

interface AddNewAddressModalProps {
	handleClose: () => void;
	onSubmit: (address: AddressFormFields) => void;
	open: boolean;
}

const AddNewAddressModal = ({ handleClose, onSubmit, open }: AddNewAddressModalProps) => {
	const dispatch = useDispatch();
	const [clearKey, setClearKey] = useState(new Date().getTime());
	const { formState, handleSubmit, register, reset, setValue, watch } = useFormContext<AddressFormFields>();
	const formFieldData = { formState, register, schema };
	const stateValue = watch("state") ?? "";
	const [validateAddressRequest, { data: suggestedAddress, isLoading: isValidateAddressLoading }] =
		useValidateAddressMutation();
	const [trigger, { data: addressSuggestions }] = useLazyGetAddressSuggestionsQuery();
	const fullNameInput = watch("fullName") || "";
	const addressInput = watch("address");
	const debouncedAddressInput = useDebounce(addressInput, 300);
	const options = addressSuggestions?.slice(0, 15) ?? [];

	const [modalState, modalStateDispatch] = useReducer(reducer, MODAL_STATE.edit);

	useEffect(() => {
		if (debouncedAddressInput && debouncedAddressInput.length > 4) {
			trigger(debouncedAddressInput);
		}
		if (!debouncedAddressInput) {
			dispatch(addressApiSlice.util.resetApiState());
		}
	}, [debouncedAddressInput, dispatch, trigger]);

	const handleAddressSelect = (_event: SyntheticEvent, value: AddressSuggestionViewModel | string | null) => {
		if (value && typeof value !== "string") {
			value.line2 && setValue("address2", value.line2);
			value.city && setValue("city", value.city, { shouldValidate: true });
			value.state && setValue("state", value.state, { shouldValidate: true });
			value.zip && setValue("zip", value.zip, { shouldValidate: true });
			value.county && setValue("county", value.county, { shouldValidate: true });
		}
	};

	const closeAndClear = () => {
		handleClose();
		reset();
		setClearKey(new Date().getTime());
		modalStateDispatch({ type: MODAL_STATE.edit });
	};

	const handleClearIndicatorClick = () => {
		setClearKey(new Date().getTime());
		dispatch(addressApiSlice.util.resetApiState());
	};

	const handleGoBackToEdit = () => {
		modalStateDispatch({ type: MODAL_STATE.edit });
	};

	const handleFormSubmit = async (values: AddressFormFields) => {
		if (modalState === MODAL_STATE.edit) {
			modalStateDispatch({ type: MODAL_STATE.confirm });
			const value = {
				line1: values.address,
				line2: values.address2 ?? "",
				city: values.city ?? "",
				state: values.state ?? "",
				zip: values.zip ?? ""
			};

			try {
				const validatedAddress = await validateAddressRequest(value).unwrap();

				if (!validatedAddress.isCorrected) {
					onSubmit(values);
					closeAndClear();
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			if (values.validateChoice === RADIO_BUTTON_VALUES.entered) {
				onSubmit(values);
			} else if (suggestedAddress) {
				const suggestedValues: AddressFormFields = {
					fullName: suggestedAddress.name,
					address: suggestedAddress.line1,
					address2: suggestedAddress.line2,
					zip: suggestedAddress.zip,
					contactPhoneNumber: values.contactPhoneNumber,
					email: values.email,
					city: suggestedAddress.city,
					state: suggestedAddress.state,
					county: suggestedAddress.county
				};
				onSubmit(suggestedValues);
			}
			closeAndClear();
		}
	};

	return (
		<StyledDialog
			onClose={closeAndClear}
			open={open}
		>
			<form
				onSubmit={(event) => {
					event.stopPropagation();
					handleSubmit(handleFormSubmit)(event);
				}}
				noValidate
			>
				<DialogTitle
					title={AddNewAddressTitle}
					handleClose={closeAndClear}
				/>
				<Content hidden={modalState === MODAL_STATE.confirm}>
					<RequiredFieldsText>{RequiredFieldNotice}</RequiredFieldsText>
					<FieldGroup>
						<TextField
							{...getFormFieldProps({
								name: "fullName",
								helperText: FullNameHelperText,
								characterLimit: {
									currentLength: fullNameInput.length,
									limit: FULL_NAME_MAX_LENGTH
								},
								...formFieldData
							})}
							InputLabelProps={{ shrink: Boolean(fullNameInput) || undefined }}
							label={FullNameLabel}
							size="small"
						/>
					</FieldGroup>
					<Autocomplete
						key={clearKey}
						freeSolo={true}
						getOptionLabel={(option) => {
							return typeof option === "string" ? option : (option.line1 ?? "");
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								{...getFormFieldProps({
									name: "address",
									...formFieldData,
									helperText: AddressHelperText
								})}
								label={AddressLabel}
								size="small"
							/>
						)}
						renderOption={(props, option) => {
							return (
								<AutocompleteOption
									{...props}
									key={JSON.stringify(option)}
								>
									<div>{option.line1}</div>
									{option.line2 && <div>{option.line2}</div>}
									<div>
										{option.city}, {option.state}, {option.zip}
									</div>
								</AutocompleteOption>
							);
						}}
						componentsProps={{ clearIndicator: { onClick: handleClearIndicatorClick } }}
						ListboxComponent={ListboxComponent}
						onChange={handleAddressSelect}
						options={options}
					/>
					<TextField
						{...getFormFieldProps({ name: "address2", ...formFieldData })}
						InputLabelProps={{ shrink: Boolean(watch("address2")) || undefined }}
						label={Address2Label}
						size="small"
					/>
					<TextField
						{...getFormFieldProps({ name: "city", ...formFieldData })}
						InputLabelProps={{ shrink: Boolean(watch("city")) || undefined }}
						label={CityLabel}
						size="small"
					/>
					<SplitRow>
						<FormControl>
							<InputLabel
								id="state-label"
								htmlFor="state"
								size="small"
							>
								{StateLabel}
								<b> *</b>
							</InputLabel>
							<Select
								{...getFormFieldProps({ name: "state", helperText: null, ...formFieldData })}
								label={StateLabel}
								labelId="state-label"
								value={stateValue}
								size="small"
							>
								{states.map((state) => (
									<MenuItem
										key={state}
										value={state}
									>
										{state}
									</MenuItem>
								))}
							</Select>
							{formState.errors.state && (
								<FormHelperText error>
									<HelperText
										isError
										name="state"
										text={formState.errors.state.message}
									/>
								</FormHelperText>
							)}
						</FormControl>
						<TextField
							{...getFormFieldProps({ name: "zip", ...formFieldData })}
							InputLabelProps={{ shrink: Boolean(watch("zip")) || undefined }}
							label={ZipLabel}
							size="small"
							inputProps={{
								maxLength: 10
							}}
						/>
						<TextField
							{...getFormFieldProps({ name: "county", ...formFieldData })}
							InputLabelProps={{ shrink: Boolean(watch("county")) || undefined }}
							label={CountyLabel}
							size="small"
						/>
					</SplitRow>
					<Divider />
				</Content>
				<BottomSection hidden={modalState === MODAL_STATE.confirm}>
					<div>
						<div>{ContactInformationTitle}</div>
						<div>{ContactInformationNotice} </div>
						<div>{RequiredFieldNotice}</div>
					</div>
					<TextField
						{...getFormFieldProps({ name: "contactPhoneNumber", ...formFieldData })}
						InputLabelProps={{ shrink: Boolean(watch("contactPhoneNumber")) || undefined }}
						label={PhoneLabel}
						size="small"
						InputProps={{
							inputComponent: MaskedPhoneTextField as any
						}}
					/>
					<TextField
						{...getFormFieldProps({ name: "email", ...formFieldData })}
						InputLabelProps={{ shrink: Boolean(watch("email")) || undefined }}
						label={EmailLabel}
						size="small"
					/>
				</BottomSection>
				{modalState === MODAL_STATE.confirm && (
					<VerifyAddressContent sx={{ paddingBottom: isValidateAddressLoading ? 0 : undefined }}>
						<ConfirmAddress
							suggestedAddress={suggestedAddress}
							isLoading={isValidateAddressLoading}
						/>
					</VerifyAddressContent>
				)}
				<div hidden={modalState === MODAL_STATE.confirm}>
					<FooterActions
						handleClose={closeAndClear}
						submitsForm
						isAddNewAddressModal
					/>
				</div>
				<div
					hidden={
						modalState === MODAL_STATE.edit ||
						(isValidateAddressLoading && modalState === MODAL_STATE.confirm)
					}
				>
					<FooterActions
						cancelText={EditAddressButton}
						confirmText={SaveAndContinueButton}
						handleClose={handleGoBackToEdit}
						submitsForm
						isAddNewAddressModal
					/>
				</div>
			</form>
		</StyledDialog>
	);
};

export default AddNewAddressModal;
