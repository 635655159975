import { Table, TableCell, TableRow, TableBody, Tabs, Tab } from "@mui/material";
import {
	SaveDraftTableContainer,
	SaveDraftExteriorTableHeader,
	SaveDraftTableDisclaimer,
	SaveDraftTableWrapper,
	SaveDraftDraftTableCell
} from "./SaveDraftTable.styles";
import {
	SaveDraftTableDisclaimerText,
	NewOrderOpen,
	NewOrderRemoveButton,
	SaveDraftDraftTab,
	SaveDraftTemplateTab,
	SaveDraftTHeadDate,
	SaveDraftTHeadDraft,
	SaveDraftTHeadAccount,
	SaveDraftTHeadDesigner,
	SaveDraftTHeadProductLine,
	SaveDraftTableSearchText
} from "../../constants";
import { SyntheticEvent, useState, Fragment, MouseEvent, useMemo } from "react";
import { useGetDraftOrdersQuery } from "features/orderApi";
import { LinkButton } from "components/Common/Link";
import SaveDraftNoDrafts from "../SaveDraftNoDrafts";
import SaveDraft400Error from "../SaveDraft400Error";
import SaveDraftTableSkeleton from "./SaveDraftTableSkeleton";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import { format } from "date-fns";
import SortableTableHead, {
	HeadCell,
	Order
} from "components/NewOrders/SaveDraft/SaveDraftTable/components/SortableTableHead.tsx";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import SaveDraftTableFilters from "./components/SaveDraftTableFilters.tsx";
import useSession from "hooks/useSession.ts";
import { CacheKeys, InitialDraftFilterOptions } from "pages/OrderPages/OrderListPage/orderListConstants.ts";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { getFilteredDrafts } from "./saveDraftUtils.ts";
import { UnavailableDataPlaceholderText } from "constants/text.ts";

function a11yProps(index: number) {
	return {
		id: `new-order-save-draft-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

const headCells = [
	{
		id: "updatedAt",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDate,
		sortable: true
	},
	{
		id: "draft",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDraft,
		sortable: false
	},
	{
		id: "account",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadAccount,
		sortable: false
	},
	{
		id: "designer",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDesigner,
		sortable: false
	},
	{
		id: "productLine",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadProductLine,
		sortable: false
	},
	{
		id: "empty",
		numeric: false,
		disablePadding: false,
		label: "",
		sortable: false
	}
] as const satisfies HeadCell[];

type CellIds = (typeof headCells)[number]["id"];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type KeysOf = keyof CellIds;

function getComparator(order: Order, orderBy: CellIds): (a: any, b: any) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

const SaveDraftTable = () => {
	const [filterOptions, setFilterOptions] = useSession<FilterOpts>(CacheKeys.DraftFilters, InitialDraftFilterOptions);

	const [order, setOrder] = useState<Order>("desc");
	const [orderBy, setOrderBy] = useState<CellIds>("updatedAt");
	const [value, setValue] = useState(0);
	const [searchValue, setSearchValue] = useState<string | null>(null);

	const { data: drafts, error, isLoading } = useGetDraftOrdersQuery({});

	const sortedDrafts = useMemo(() => drafts?.slice().sort(getComparator(order, orderBy)), [drafts, order, orderBy]);
	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleRequestSort = (_event: MouseEvent<unknown>, property: CellIds) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const searchOptions = useMemo(() => {
		return (
			drafts
				?.map((draft?: { draftName?: string | null }) => draft?.draftName)
				.filter((option) => option !== undefined && option !== null) ?? []
		);
	}, [drafts]);

	const handleDraftSearch = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values ?? null);
	};

	const filteredDrafts = useMemo(
		() => getFilteredDrafts(filterOptions, sortedDrafts!),
		[filterOptions, sortedDrafts]
	);

	const getDraftTableRow = (draft: DraftOrderViewModel) => {
		const config = draft?.configurations?.reduce((accum, current, index) => {
			if (index !== 0) {
				return {
					...accum,
					accountId: [accum.accountId, `${current.accountNumber} - ${current.accountName}`].join(","),
					productLineDescription:
						accum.productLineDescription !== ""
							? accum.productLineDescription
							: UnavailableDataPlaceholderText
				};
			} else
				return {
					...accum,
					accountId: `${accum.accountNumber} - ${accum.accountName}`,
					productLineDescription:
						accum.productLineDescription !== ""
							? accum.productLineDescription
							: UnavailableDataPlaceholderText
				};
		}, draft?.configurations[0]);

		return (
			<Fragment>
				<TableCell
					data-testid="new-order-save-draft-table-date-content"
					component="th"
					scope="row"
				>
					{format(new Date(String(draft.updatedAt)), "MM/dd/yy")}
				</TableCell>
				<SaveDraftDraftTableCell data-testid="new-order-save-draft-table-draft-name-content">
					{draft.draftName}
				</SaveDraftDraftTableCell>
				<TableCell data-testid="new-order-save-draft-table-account-content">
					{config?.accountId?.split(",").map((accountId, index) => <div key={index}>{accountId}</div>)}
				</TableCell>
				<TableCell data-testid="new-order-save-draft-table-designer-name-content">
					{draft.designerName}
				</TableCell>
				<TableCell data-testid="new-order-save-draft-table-brand-content">
					{config?.productLineDescription}
				</TableCell>
				<TableCell>
					<LinkButton data-testid="new-order-save-draft-table-edit-link">{NewOrderOpen}</LinkButton>

					<LinkButton
						variant="remove"
						data-testid="new-order-save-draft-table-remove-link"
					>
						{NewOrderRemoveButton}
					</LinkButton>
				</TableCell>
			</Fragment>
		);
	};

	return (
		<>
			{isLoading ? (
				<SaveDraftTableSkeleton />
			) : (
				<SaveDraftTableContainer>
					<SaveDraftExteriorTableHeader>
						<div>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Save draft table tabs"
							>
								<Tab
									data-testid="new-order-save-draft-draft-tab"
									label={SaveDraftDraftTab}
									{...a11yProps(0)}
								/>
								<Tab
									data-testid="new-order-save-draft-template-tab"
									label={SaveDraftTemplateTab}
								/>
							</Tabs>
						</div>

						<SaveDraftTableFilters
							filterOptions={filterOptions}
							setFilterOptions={setFilterOptions}
						/>

						<SuggestedSearch
							ariaLabel="search-drafts"
							dataTestId="new-order-save-draft-table-search"
							dropDownOpenOnChar={3}
							placeholder={SaveDraftTableSearchText}
							handleSearch={(event, values) => handleDraftSearch(event, values ?? "")}
							id="new-order-draft-table-search-drafts"
							searchOptions={searchOptions ?? []}
							searchValue={searchValue ?? ""}
							size="small"
						/>
					</SaveDraftExteriorTableHeader>

					<SaveDraftTableDisclaimer>{SaveDraftTableDisclaimerText}</SaveDraftTableDisclaimer>

					{error && "status" in error && error.status === 400 && <SaveDraft400Error />}

					{sortedDrafts && sortedDrafts?.length > 0 && !isLoading ? (
						<SaveDraftTableWrapper>
							<Table
								data-testid="new-order-save-draft-table"
								aria-label="Save draft table"
							>
								<SortableTableHead
									orderBy={orderBy}
									order={order}
									onRequestSort={handleRequestSort}
									headCells={headCells}
								/>
								<TableBody>
									{filteredDrafts?.map((draft) => {
										const foundSearch = draft.draftName
											?.toUpperCase()
											?.includes(searchValue?.toUpperCase() ?? "");
										if (foundSearch) {
											return (
												<TableRow key={draft.draftOrderId}>{getDraftTableRow(draft)}</TableRow>
											);
										} else {
											return <></>;
										}
									})}
								</TableBody>
							</Table>
						</SaveDraftTableWrapper>
					) : (
						<SaveDraftNoDrafts />
					)}
				</SaveDraftTableContainer>
			)}
		</>
	);
};

export default SaveDraftTable;
