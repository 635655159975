import { AutoCompleteContainer } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { GlobalAttributeFields } from "../forms/schema";
import { SelectAccountNumber, SelectBillToNumber } from "../../constants";
import { useLazyGetAccountsQuery } from "features/accountApi";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";
import { convertToTitleCase } from "utils/string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { newOrderActions } from "features/newOrder";

interface StepFourProps {
	isUpdate?: boolean;
}

const StepFour = ({ isUpdate }: StepFourProps) => {
	const [trigger, { data: accounts, isLoading }] = useLazyGetAccountsQuery();
	const { control, getValues, watch, setValue } = useFormContext<GlobalAttributeFields>();
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);
	const dispatch = useDispatch();

	const selectedAccount = watch("accountNumber");

	useEffect(() => {
		const productLineCode = isUpdate ? updatedGlobalAttributes?.productLineCode : getValues()?.productLine?.code;

		if (productLineCode) {
			trigger(productLineCode);
		}
	}, [getValues, isUpdate, trigger, updatedGlobalAttributes?.productLineCode]);

	return (
		<AutoCompleteContainer>
			<Controller
				name="accountNumber"
				control={control}
				render={({ field: { onChange, value = null }, fieldState: { error } }) => (
					<AutoComplete
						required
						value={value}
						disableClearable
						isLoading={isLoading}
						label={SelectAccountNumber}
						getOptionLabel={(option) =>
							option?.accountNumber && option?.accountName
								? `${option.accountNumber} - ${option.accountName}`
								: (option?.accountNumber ?? "")
						}
						onChange={(_event, value) => {
							setValue("billToNumber", undefined);
							if (isUpdate) {
								const globalAttributesToBeUpdated = {
									accountNumber: value
								};
								dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
							}
							onChange(value);
						}}
						options={accounts ?? []}
						dataTestId="select-account-number"
						isError={!!error}
						errorText={error?.message}
					/>
				)}
			/>

			<Controller
				name="billToNumber"
				control={control}
				render={({ field: { onChange, value = null }, fieldState: { error } }) => (
					<AutoComplete
						required
						value={value}
						disableClearable
						disabled={!selectedAccount}
						label={SelectBillToNumber}
						onChange={(_event, value) => {
							if (isUpdate) {
								const globalAttributesToBeUpdated = {
									billToNumber: value
								};
								dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
							}
							onChange(value);
						}}
						getOptionLabel={(option: BillToInfoViewModel) => {
							const projectName =
								option.projectName && option.projectName.trim() !== ""
									? option.projectName + " - "
									: "";
							const logisticsMode = option.logisticsMode
								? " - " + convertToTitleCase(option.logisticsMode)
								: "";
							return option
								? `${projectName}${option.city}, ${option.state}${logisticsMode} - ${option.billToId}`
								: "";
						}}
						options={selectedAccount?.billTos?.filter((billTo) => billTo.isActive) ?? []}
						dataTestId="select-bill-to-number"
						isError={!!error}
						errorText={error?.message}
					/>
				)}
			/>
		</AutoCompleteContainer>
	);
};

export default StepFour;
