import { AutoCompleteContainer, SelectionDetails } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { GlobalAttributeFields } from "../forms/schema";
import { SelectFinish, SelectShape, SelectSpecies } from "../../constants";
import { useLazyGetStyleDataQuery } from "features/globalsApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useEffect, useState } from "react";
import { newOrderActions } from "features/newOrder";
import { sortGlobalByDescription } from "components/NewOrders/utils/NewOrderUtils";

interface StepTwoProps {
	isUpdate?: boolean;
}

const StepTwo = ({ isUpdate }: StepTwoProps) => {
	const [initializing, setInitializing] = useState(true);
	const { control, watch, clearErrors, setValue } = useFormContext<GlobalAttributeFields>();
	const dispatch = useDispatch();
	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedSpecies = watch("species");

	const [trigger, { data: doorStyleData, isLoading }] = useLazyGetStyleDataQuery();

	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);

	// Trigger the doorStyle

	useEffect(() => {
		if (selectedDoorStyle?.id) {
			trigger(selectedDoorStyle?.id);
		}
	}, [selectedDoorStyle?.id, trigger]);

	useEffect(() => {
		if (!isUpdate) {
			return;
		}

		if (
			initializing &&
			doorStyleData &&
			updatedGlobalAttributes?.shapeId &&
			updatedGlobalAttributes?.speciesId &&
			updatedGlobalAttributes?.finishId
		) {
			const shapeValue = doorStyleData?.shapes?.find((shape) => shape.id === updatedGlobalAttributes?.shapeId);

			const speciesValue = doorStyleData?.species?.find(
				(species) => species.id === updatedGlobalAttributes?.speciesId
			);

			const finishValue = speciesValue?.finishes?.find(
				(finish) => finish.id === updatedGlobalAttributes?.finishId
			);

			if (shapeValue) {
				setValue("shape", shapeValue);
			}
			if (speciesValue) {
				setValue("species", speciesValue);
			}
			if (finishValue) {
				setValue("finish", finishValue);
			}

			if (shapeValue && speciesValue && finishValue) {
				setInitializing(false);
			}
		}
	}, [
		doorStyleData,
		doorStyleData?.shapes,
		doorStyleData?.species,
		initializing,
		isUpdate,
		setValue,
		updatedGlobalAttributes?.finishId,
		updatedGlobalAttributes?.shapeId,
		updatedGlobalAttributes?.speciesId
	]);

	const shapesOptions = doorStyleData?.shapes ?? [];
	const speciesOptions = doorStyleData?.species ?? [];
	const finishesOptions = selectedSpecies?.finishes ?? [];

	return (
		<AutoCompleteContainer>
			<SelectionDetails data-testid="global-attribute-modal-selection">
				{`${isUpdate ? updatedGlobalAttributes?.productLine : selectedProductLine?.description}, ${selectedDoorStyle?.description}`}
			</SelectionDetails>
			{shapesOptions.length !== 0 && (
				<Controller
					name="shape"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectShape}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated = {
										shape: value.description,
										shapeCode: value.code,
										shapeId: value.id
									};
									dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
								}
								clearErrors("shape");
								onChange(value);
							}}
							options={sortGlobalByDescription(shapesOptions)}
							dataTestId="select-shape"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{speciesOptions.length !== 0 && (
				<Controller
					name="species"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectSpecies}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated = {
										species: value.description,
										speciesCode: value.code,
										speciesId: value.id
									};
									dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
								}
								clearErrors("species");
								onChange(value);
							}}
							options={sortGlobalByDescription(speciesOptions)}
							dataTestId="select-wood-species"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{(!selectedSpecies || finishesOptions.length !== 0) && (
				<Controller
					name="finish"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							value={value}
							isLoading={isLoading}
							disabled={!selectedSpecies}
							disableClearable
							label={SelectFinish}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated = {
										finish: value.description,
										finishCode: value.code,
										finishId: value.id
									};
									dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
								}
								clearErrors("finish");
								onChange(value);
							}}
							options={sortGlobalByDescription(finishesOptions)}
							dataTestId="select-finish"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
		</AutoCompleteContainer>
	);
};

export default StepTwo;
