import { useMemo, useState } from "react";
import { OrderDetails, ShippingDetails, submittingReplacementOrderActions } from "features/submittingReplacementOrder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { FormSchema as ShipToFormFields } from "components/Replacements/ShipToForm/schema";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import ShipToForm from "components/ShipToForm/ShipToForm";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { formatPhoneNumber } from "components/Replacements/ReplacementsUtil";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import { BillToViewModel } from "data/api/v1/model/bill-to-view-model";

interface ReplacementsShipToFormProps {
	accounts: CustomerAccountViewModel | undefined;
	handleEditShippingInformation: () => void;
}
const ReplacementsShipToForm = ({ accounts, handleEditShippingInformation }: ReplacementsShipToFormProps) => {
	const dispatch = useDispatch();
	const replacementOrderDetails: OrderDetails = useSelector(
		(state: RootState) => state.submittingReplacementOrder.order
	);

	const newOrderAddresses: ShipToViewModel[] = useSelector(
		(state: RootState) => state.submittingReplacementOrder.newAddresses ?? []
	);
	const shippingInfo: ShipToViewModel[] = useMemo(
		() =>
			accounts?.billTos
				?.filter((billTo: BillToViewModel) => billTo.isSelected)
				.map((billTo: BillToViewModel) => billTo.shipTos)
				?.flat()
				?.concat(newOrderAddresses) ?? [...newOrderAddresses],
		[accounts?.billTos, newOrderAddresses]
	);

	const isCustomerPickup = replacementOrderDetails?.isCustomerPickup ?? false;

	const defaultShipToAddress = useMemo(
		() => replacementOrderDetails?.billingDetails?.shipTos?.filter((address) => address?.isSelected)?.[0],
		[replacementOrderDetails?.billingDetails?.shipTos]
	);

	const [shippingInformation, setShippingInformation] = useState<ShipToViewModel | undefined>(
		replacementOrderDetails?.shippingDetails ?? defaultShipToAddress ?? null
	);

	const addressFields: ShipToViewModel | ShippingDetails | undefined =
		shippingInformation ?? replacementOrderDetails?.shippingDetails;

	const phoneNumber = formatPhoneNumber(
		addressFields && "phoneNumber" in addressFields?.address ? addressFields?.address?.phoneNumber : ""
	);

	const handleOnCancel = () => {
		handleEditShippingInformation();
	};

	const handleAddNewAddressModalSubmit = (address: AddressFormFields) => {
		handleOnSave(address, isCustomerPickup);
	};

	const handleSetShippingInformation = (address: ShipToViewModel | undefined) => {
		setShippingInformation(address);
	};

	const handleOnSave = (fields: ShipToFormFields | AddressFormFields, formIsCustomerPickup?: boolean) => {
		const addressFormFields: AddressFormFields | undefined = "address2" in fields ? fields : undefined;
		const phoneNumber = "contactPhoneNumber" in fields ? fields.contactPhoneNumber : fields.phoneNumber;
		const deliveryInstructions = "deliveryInstructions" in fields ? fields.deliveryInstructions : "";

		handleEditShippingInformation();
		const editShippingDetails = {
			shippingDetails: {
				shipToId: addressFormFields || formIsCustomerPickup ? undefined : shippingInformation?.shipToId,
				address: {
					name:
						addressFormFields?.fullName ??
						shippingInformation?.address.name ??
						defaultShipToAddress?.address.name,
					email: fields?.email ?? defaultShipToAddress?.address.email,
					phoneNumber: phoneNumber ?? defaultShipToAddress?.address.phoneNumber,
					line1:
						addressFormFields?.address ??
						shippingInformation?.address.line1 ??
						defaultShipToAddress?.address.line1,
					line2:
						addressFormFields?.address2 ??
						shippingInformation?.address.line2 ??
						defaultShipToAddress?.address.line2,
					line3: addressFormFields
						? ""
						: (shippingInformation?.address.line3 ?? defaultShipToAddress?.address.line3 ?? ""),
					city:
						addressFormFields?.city ??
						shippingInformation?.address.city ??
						defaultShipToAddress?.address.city,
					state:
						addressFormFields?.state ??
						shippingInformation?.address.state ??
						defaultShipToAddress?.address.state,
					zip:
						addressFormFields?.zip ?? shippingInformation?.address.zip ?? defaultShipToAddress?.address.zip,
					county: addressFormFields?.county ?? shippingInformation?.address.county
				}
			},
			labelComments: formIsCustomerPickup ? "" : deliveryInstructions,
			isCustomerPickup: formIsCustomerPickup
		};
		dispatch(submittingReplacementOrderActions.updateShippingOrderDetails(editShippingDetails));
		if (addressFormFields) {
			dispatch(submittingReplacementOrderActions.addNewAddress(editShippingDetails.shippingDetails));
		}
	};

	return (
		<div>
			<ShipToForm
				allowLabelComments={accounts?.allowLabelComments ?? false}
				analyticsDataId="replacements-review-new-address-link"
				combinedAddresses={shippingInfo}
				defaultFormValues={{
					email:
						shippingInformation?.address.email ??
						replacementOrderDetails?.shippingDetails?.address.email ??
						"",
					phoneNumber,
					deliveryInstructions: replacementOrderDetails?.labelComments ?? "",
					address: addressFields
				}}
				customerPickupAllowed={accounts?.customerPickupAllowed ?? false}
				handleAddNewAddressModalSubmit={handleAddNewAddressModalSubmit}
				handleOnCancel={handleOnCancel}
				handleOnSave={handleOnSave}
				handleSetShippingInformation={handleSetShippingInformation}
				isCustomerPickupDefault={isCustomerPickup}
				showCancelSaveButtons
			/>
		</div>
	);
};

export default ReplacementsShipToForm;
