import { Button } from "@mui/material";
import {
	AddItemText,
	CreateYourItemSubtitleText,
	CreateYourItemText,
	DownloadLatestText,
	DraftANewOrderText,
	FindOriginalOrderText,
	FindOriginalText,
	HereText,
	ImportCSVText,
	ImportFileText,
	ManualOrderEntryText,
	ReplacementOrAddOnText,
	SupportedFilesText
} from "constants/text";
import {
	DraftNewOrderHeader,
	GridChildOneStyles,
	GridChildTwoContainer,
	GridChildTwoStyles,
	ImportCSVTextContainer,
	NewOrdersContentContainer,
	NewOrdersDialog,
	NewOrdersHeaderGrid,
	NewOrdersLayoutContainer,
	OrderOrAddOnText,
	SupportedFilesTextSize,
	UnsetSecondaryCopy
} from "components/NewOrders/NewOrders.styles";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router";
import AccountBillToDialogForm from "components/NewOrders/AccountBillToDialogForm";
import { NewOrderModalTracking } from "data/api/v1";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import CSVUpload from "components/NewOrders/CSVUpload";
import { NewOrder, newOrderActions } from "features/newOrder";
import { salesforceBaseURL } from "constants/endpoints";
import Link from "@mui/material/Link";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { RefObject, useState } from "react";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import SaveDraftTable from "components/NewOrders/SaveDraft/SaveDraftTable/SaveDraftTable.tsx";
import { updateOrderDetails } from "../utils/OrderDetailUtil";

interface Props {
	file?: NewOrder;
}

const NewOrderPage = ({ file }: Props) => {
	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const modalState = useSelector((state: RootState) => state.newOrder.modalStatus);
	const modalStateCSV = modalState === NewOrderModalTracking.CSV_UPLOAD;
	const modalStateAccount = modalState === NewOrderModalTracking.SELECT_BILLTO_ACCOUNT;

	const handleImportFile = () => {
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.CSV_UPLOAD));
	};

	const handleCloseModal = () => {
		if (!modalStateAccount) {
			dispatch(newOrderActions.clearNewOrder());
		}
	};

	const handleFindOriginalOrder = () => {
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.CSV_UPLOAD));
		navigate("/projects");
	};

	const handleAddItem = () => {};

	const handleStartOver = () => {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
	};

	const parsedCSV: ImportOrderViewModel = useSelector(
		(state: RootState) => state.newOrder.parsedCSV
	) as ImportOrderViewModel;

	const handleModalContinue = () => {
		const csvConfigurations = parsedCSV?.configurations?.map((config) => config ?? []);
		updateOrderDetails(csvConfigurations, dispatch);
		navigate("/new-order/build-order");
	};

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				getContainerElement={calcHeightOfHeaderForContentTopOffset}
				includeDetails={false}
				includeStepper={false}
				isNewOrderPage
			/>

			<NewOrdersContentContainer marginTopOffset={contentOffset}>
				<DraftNewOrderHeader>{DraftANewOrderText}</DraftNewOrderHeader>

				<NewOrdersHeaderGrid>
					<GridChildOneStyles>
						<ImportCSVTextContainer>{ImportCSVText}</ImportCSVTextContainer>
						<UnsetSecondaryCopy>{CreateYourItemText}</UnsetSecondaryCopy>
						<SupportedFilesTextSize>{SupportedFilesText}</SupportedFilesTextSize>
						<Button
							onClick={handleImportFile}
							fullWidth
							variant="contained"
							startIcon={<UploadIcon sx={{ marginBottom: "3px" }} />}
						>
							{ImportFileText}
						</Button>

						<UnsetSecondaryCopy>
							{DownloadLatestText}{" "}
							<Link
								href={`${salesforceBaseURL}/s/design-software`}
								data-testid="newOrder-download-latest-catalogs"
							>
								{HereText}
							</Link>
						</UnsetSecondaryCopy>
					</GridChildOneStyles>

					<GridChildTwoStyles>
						<GridChildTwoContainer>
							<OrderOrAddOnText>{ReplacementOrAddOnText}</OrderOrAddOnText>
							<Button
								onClick={handleFindOriginalOrder}
								variant="outlined"
								startIcon={
									<ArrowOutwardIcon
										fontSize="small"
										sx={{ marginBottom: "2px" }}
									/>
								}
								sx={{
									display: "flex",
									alignItems: "center",
									height: "fit-content",
									width: "max-content"
								}}
							>
								{FindOriginalOrderText}
							</Button>
							<UnsetSecondaryCopy>{FindOriginalText}</UnsetSecondaryCopy>
						</GridChildTwoContainer>

						<div style={{ height: 20, background: "#f7f7f7" }} />

						<GridChildTwoContainer>
							<OrderOrAddOnText>{ManualOrderEntryText}</OrderOrAddOnText>
							<Button
								onClick={handleAddItem}
								variant="outlined"
								startIcon={
									<AddIcon
										fontSize="small"
										sx={{ marginBottom: "2px" }}
									/>
								}
								sx={{
									display: "flex",
									alignItems: "center",
									height: "fit-content",
									width: "max-content"
								}}
							>
								{AddItemText}
							</Button>
							<UnsetSecondaryCopy>{CreateYourItemSubtitleText}</UnsetSecondaryCopy>
						</GridChildTwoContainer>
					</GridChildTwoStyles>
				</NewOrdersHeaderGrid>

				<SaveDraftTable />
			</NewOrdersContentContainer>

			<NewOrdersDialog
				open={modalStateCSV || modalStateAccount}
				onClose={handleCloseModal}
				disableEscapeKeyDown={modalStateAccount}
			>
				{modalStateAccount && (
					<AccountBillToDialogForm
						handleFindOriginalOrder={handleStartOver}
						handleModalContinue={handleModalContinue}
					/>
				)}

				{modalStateCSV && <CSVUpload handleClose={handleCloseModal} />}
			</NewOrdersDialog>
		</NewOrdersLayoutContainer>
	);
};

export default NewOrderPage;
