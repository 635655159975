import { UnknownAction } from "@reduxjs/toolkit";
import { UnavailableDataPlaceholderText } from "constants/text";
import { ImportConfigurationViewModel, OrderDetailViewModel, OrderType } from "data/api/v1";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { Dispatch } from "react";
import { arrayGroupBy } from "utils/array";
import { v4 as uuidv4 } from "uuid";

export const GetBackorderCount = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order?.partInfo?.partsQuantityBackordered !== undefined && order?.partInfo?.partsQuantityBackordered > 0
	);
	const lineItemsCabinetOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order.quantityBackordered !== undefined &&
			order.quantityBackordered !== null &&
			order.quantityBackordered > 0
	);
	const hasCabinetBackorder = lineItemsCabinetOnBackorder?.map((e) => e?.quantityBackordered)?.length;
	const hasPartBackorder = lineItemsOnBackorder?.map((e) => e?.partInfo?.partsQuantityBackordered)?.length;
	return Number(
		hasPartBackorder !== undefined && hasCabinetBackorder !== undefined && hasPartBackorder + hasCabinetBackorder
	);
};

export const PartsQuantityBackorderedLength = (orderDetail: OrderDetailViewModel) => {
	const lineItemsOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order?.partInfo?.partsQuantityBackordered !== undefined && order?.partInfo?.partsQuantityBackordered > 0
	);
	return lineItemsOnBackorder?.map((e) => e?.partInfo?.partsQuantityBackordered)?.length;
};

export const BackorderedCabinetCount = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsCabinetOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order.quantityBackordered !== undefined &&
			order.quantityBackordered !== null &&
			order.quantityBackordered > 0
	);

	return lineItemsCabinetOnBackorder?.map((e) => e?.quantityBackordered)?.length;
};

export const GroupAllTrackingNumbers = (orderDetail: OrderDetailViewModel): string[] => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	const groupAllByTrackingNumber = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.trackingNumber?.length && !product.truckNumber
				? product.trackingNumber
				: !product.trackingNumber;
		}
	);

	return [...(groupAllByTrackingNumber as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GroupAllTruckNumbers = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];
	const groupAllByTruckNumber = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.truckNumber?.length && !product.trackingNumber ? product.truckNumber : !product.truckNumber;
		}
	);

	return [...(groupAllByTruckNumber as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GroupTrackingAndTruckingNumbers = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	const trackingAndTrucking = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.trackingNumber && product.truckNumber;
		}
	);

	return [...(trackingAndTrucking as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GetNormalLineItems = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	return arrayGroupBy(lineItemsMap, (product: { trackingNumber: string | null; truckNumber: string | null }) => {
		return (
			(!product.trackingNumber && !product.truckNumber) ||
			product.trackingNumber === null ||
			product.truckNumber === null
		);
	});
};

export const updateOrderDetails = (
	configurations: ImportConfigurationViewModel[] | null | undefined,
	dispatch: Dispatch<UnknownAction>,
	draftOrder?: DraftOrderViewModel
) => {
	const tandemOrderId = uuidv4();
	const lineItems = configurations?.map((configuration) =>
		configuration.items.map((item, index) => ({
			description: item.description,
			name: "",
			doorStyle: configurations?.[index]?.doorStyleCode,
			finish: configurations?.[index]?.finishCode,
			unitPrice: null,
			listPrice: 0,
			extendedPrice: 0,
			lineItemNumber: item.lineNumber,
			productLine: configuration.productLine,
			quantityOrdered: item.quantity,
			sku: item.userCode,
			construction: "",
			hinge: "",
			woodSpecies: configurations?.[index]?.species,
			id: item.manufacturerCode,
			updatedAt: new Date(),
			defectCode: null
		}))
	);

	const newConfigurations = configurations?.map((config, index: number) => ({
		configurationId: uuidv4(),
		brand: config.brand,
		productLine: config.productLine,
		productLineCode: config.productLineCode,
		style: config.doorStyleCode,
		finish: config.finishCode,
		species: config.species,
		construction: "c",
		packaging: "",
		accountId: config?.accountNumber?.accountId,
		lineItems: lineItems ? lineItems[index] : [],
		billToId: config?.billToNumber?.billToId,
		total: lineItems ? lineItems[index].length : 0,
		doorHardware: "",
		drawerHardware: ""
	}));
	const submittingOrder = {
		tandemOrderId: tandemOrderId,
		jobName: null,
		poNumber: null,
		orderType: OrderType.ORIGINAL,
		designerNumber: null,
		labelComments: null,
		requestedDeliveryDate: null,
		originalOrderId: null,
		shipToId: null,
		isCustomerPickup: false,
		shipToAddress: {},
		configurations: newConfigurations
	};
	const draft = {
		...submittingOrder,
		draftOrderId: draftOrder?.draftOrderId,
		draftName: draftOrder?.draftName,
		designerName: UnavailableDataPlaceholderText
	};

	dispatch(submittingNewOrderActions.updateDraftOrder(draft));
	dispatch(submittingNewOrderActions.submittingNewOrder(submittingOrder));
};
