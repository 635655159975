import { ChangeEvent, useState } from "react";
import {
	Checkbox,
	Grid,
	Select,
	Divider,
	FormControl,
	InputLabel,
	SelectChangeEvent,
	MenuItem,
	Tooltip
} from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import { cartActions, Part, ReplacementCartItem } from "features/cart";
import { useDispatch } from "react-redux";
import { MaxQuantityPartsSelectedText } from "constants/text";

export interface ReplacePartBoxProps {
	lineItemToEdit: ReplacementCartItem;
	part: Part;
	handlePartError: (check: boolean) => void;
}

const ReplacePartBox = ({ lineItemToEdit, part, handlePartError }: ReplacePartBoxProps) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const partsPerLineItem =
		lineItemToEdit.quantityOrdered === 0 ? part.maxQuantity : part.maxQuantity / lineItemToEdit.quantityOrdered;
	const replaceablePartsQuantity =
		lineItemToEdit.wholeCabinetQuantity > 0
			? part.maxQuantity - lineItemToEdit.wholeCabinetQuantity * partsPerLineItem
			: part.maxQuantity;
	const disablePart = lineItemToEdit.wholeCabinetQuantity === lineItemToEdit.quantityOrdered;

	const lineItemToEditCopy = {
		...lineItemToEdit,
		replaceableParts: lineItemToEdit.replaceableParts.map((part: any) => ({ ...part }))
	};

	const partToEdit = lineItemToEditCopy.replaceableParts.find((item) => item.id === part.id)!;

	const handleReplacePartBoxSelected = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			handlePartError(false);
			partToEdit.partQuantity = 1;
		} else {
			partToEdit.partQuantity = 0;
		}

		dispatch(cartActions.updateDraft(lineItemToEditCopy));
	};

	const handleQuantityChanged = (event: SelectChangeEvent) => {
		partToEdit.partQuantity = Number(event.target.value);
		dispatch(cartActions.updateDraft(lineItemToEditCopy));
	};

	const onClose = () => {
		setOpen(!disablePart);
	};

	const onOpen = () => {
		setOpen(disablePart);
	};

	return (
		<>
			<Tooltip
				open={open}
				onClose={onClose}
				onOpen={onOpen}
				title={MaxQuantityPartsSelectedText}
				PopperProps={{
					sx: {
						"&[data-popper-reference-hidden]": {
							visibility: "hidden",
							"pointer-events": "none"
						}
					},
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, -30]
							}
						}
					]
				}}
			>
				<Grid
					container
					className={styles.replacementPartsContainer}
					data-testid="replacement-part-container"
				>
					<Grid
						item
						xs={2}
					>
						<Checkbox
							disabled={disablePart}
							checked={!disablePart && partToEdit.partQuantity > 0}
							sx={{ marginLeft: "10px" }}
							data-testid={`replace-part-${part.partSku}-checkbox`}
							inputProps={{ id: `replace-part-${part.id}` }}
							indeterminate={disablePart}
							onChange={handleReplacePartBoxSelected}
						/>
					</Grid>
					<Grid
						item
						xs={7.5}
						className={styles.replacementPartInformation}
					>
						<p
							className={`${styles["textBreak"]} body1`}
							data-testid={`part-${part.partSku}-sku`}
						>
							{partToEdit?.partSku}{" "}
						</p>
						<p
							className={`${styles["textBreak"]} body2`}
							data-testid={`part-${part.partSku}-description`}
						>
							{partToEdit?.description}
						</p>
					</Grid>
					<Grid
						item
						sx={{ display: "flex", alignItems: "flex-start" }}
					>
						<FormControl>
							<InputLabel htmlFor={`part-${part.partSku}-select-quantity`}>Qty</InputLabel>
							<Select
								size="small"
								disabled={partToEdit.partQuantity === 0}
								label="Qty"
								inputProps={{
									id: `part-${part.partSku}-select-quantity`,
									"data-testid": `select-quantity-for-part-${part.partSku}`
								}}
								value={partToEdit?.partQuantity === 0 ? "1" : String(partToEdit?.partQuantity)}
								data-testid={`select-quantity-for-part-${part.partSku}-outer`}
								onChange={handleQuantityChanged}
								sx={{ color: "var(--text-primary)" }}
							>
								{Array.from(Array(replaceablePartsQuantity)).map((_, index) => (
									<MenuItem
										key={index + 1}
										value={index + 1}
										data-testid={`part-${part.partSku}-quantity-option-${index + 1}`}
									>
										{index + 1}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Tooltip>
			<Divider />
		</>
	);
};

export default ReplacePartBox;
