import { SyntheticEvent, useEffect } from "react";
import {
	AccountBillToContentWrapper,
	FileContentsContainer,
	FileContentsLogo,
	SummaryProductLine
} from "./NewOrders.styles";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import {
	DoorStyleText,
	FileNameText,
	FinishText,
	SelectAccountText,
	SelectBillToText,
	noAccountText
} from "constants/text";
import { Button, FormControl, PopperProps } from "@mui/material";
import { FileContents, newOrderActions } from "features/newOrder";
import { brandCheck } from "utils/order";
import { useLazyGetAccountsQuery } from "features/accountApi";
import { useDispatch } from "react-redux";
import { NewOrderRemoveGroup, NewOrderSelectAccountError, NewOrderSelectBillToError } from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { AutoCompletePopper } from "components/Common/Autocomplete/Autocomplete.styles";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";
import { convertToTitleCase } from "utils/string";
import DeleteIcon from "@mui/icons-material/Delete";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { useLocation } from "react-router-dom";
import { ImportConfigurationViewModel } from "data/api/v1";
import GlobalFormProvider from "./AddGlobalAttributes/forms/GlobalFormProvider";
import AddGlobalAttributes from "./AddGlobalAttributes/AddGlobalAttributes";

interface AccountBillToContentProps {
	csvContents: FileContents | undefined;
	index: number;
	handleRemoveGroup?: () => void;
	isBuildOrderPage?: boolean;
	updateButtons?: boolean;
	handleUpdateAccount?: (account: CustomerAccountViewModel | null, index: number) => void;
	handleUpdateBillTo?: (billTo: BillToInfoViewModel | undefined, index: number) => void;
	handleConfigurationUpdate?: (configuration: ImportConfigurationViewModel) => void;
	setConfigurationToUpdate?: () => void;
}

const AccountBillToContent = ({
	csvContents,
	index,
	handleRemoveGroup,
	isBuildOrderPage,
	updateButtons,
	handleUpdateAccount,
	handleUpdateBillTo,
	handleConfigurationUpdate,
	setConfigurationToUpdate
}: AccountBillToContentProps) => {
	const currentConfiguration = csvContents?.configurations ? csvContents?.configurations[index] : null;
	const [trigger, { data: accounts, isLoading: isAccountsLoading }] = useLazyGetAccountsQuery();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (currentConfiguration?.productLineCode) {
			trigger(currentConfiguration?.productLineCode);
		}
	}, [currentConfiguration?.productLineCode, trigger]);

	const handleAccountChange = (_event: SyntheticEvent, value: CustomerAccountViewModel) => {
		dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: value }));
		dispatch(submittingNewOrderActions.updateDraftAccountNumber({ index: index, newAccountNumber: value }));
		handleUpdateAccount?.(value, index);
		if (currentConfiguration?.billToNumber !== null) {
			dispatch(newOrderActions.updateBillToNumber({ index: index, newBillToNumber: undefined }));
			dispatch(submittingNewOrderActions.updateDraftBillToNumber({ index: index, newBillToNumber: undefined }));
			handleUpdateBillTo?.(undefined, index);
		}
	};

	const handleBillToChange = (_event: SyntheticEvent, value: BillToInfoViewModel) => {
		if (!currentConfiguration?.accountNumber) {
			dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: null }));
			dispatch(submittingNewOrderActions.updateDraftAccountNumber({ index: index, newAccountNumber: null }));
			handleUpdateAccount?.(null, index);
		} else {
			dispatch(newOrderActions.updateBillToNumber({ index: index, newBillToNumber: value }));
			dispatch(submittingNewOrderActions.updateDraftBillToNumber({ index: index, newBillToNumber: value }));
			handleUpdateBillTo?.(value, index);
		}
	};

	const CustomPopper = (props: PopperProps) => {
		if (!currentConfiguration?.accountNumber) {
			return null;
		}
		return <AutoCompletePopper {...props} />;
	};

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
		if (!currentConfiguration?.accountNumber) {
			dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: null }));
			dispatch(submittingNewOrderActions.updateDraftAccountNumber({ index: index, newAccountNumber: null }));
		}
	};

	return (
		<AccountBillToContentWrapper
			isBuildOrderPage={isBuildOrderPage}
			isBillToError={currentConfiguration?.billToNumber === null}
		>
			<FileContentsContainer isBuildOrderPage={isBuildOrderPage}>
				<FileContentsLogo isBuildOrderPage={isBuildOrderPage}>
					<StyledSummaryCardLogo
						parentBrand={currentConfiguration?.parentBrand}
						className="body2"
						src={
							brandCheck(currentConfiguration?.parentBrand)
								? `/assets/manufacture_logos/${currentConfiguration?.parentBrand}.png`
								: "/assets/tandem_logos/cwg_logo.png"
						}
						alt={
							brandCheck(currentConfiguration?.parentBrand)
								? (currentConfiguration?.parentBrand ?? "")
								: "CabinetworksGroup Logo"
						}
					/>
					{location.pathname == "/new-order" && (
						<SummaryProductLine>{currentConfiguration?.productLine}</SummaryProductLine>
					)}
				</FileContentsLogo>
				{!isBuildOrderPage && (
					<>
						<div className="subtitle2">
							{FileNameText}
							<div className="body2">{csvContents?.fileName}</div>
						</div>
						{csvContents?.configurations?.length && (
							<div className="subtitle2">
								{DoorStyleText}
								<div className="body2">{currentConfiguration?.doorStyle}</div>
							</div>
						)}
						{csvContents?.configurations?.length && (
							<div className="subtitle2">
								{FinishText}
								<div className="body2">{currentConfiguration?.finish}</div>
							</div>
						)}
					</>
				)}
			</FileContentsContainer>

			<FormControl
				size="small"
				required
				fullWidth
				sx={{ alignSelf: "flex-start" }}
			>
				<AutoComplete
					key={currentConfiguration?.accountNumber?.accountId ?? ""}
					options={accounts ?? []}
					onMouseDownCapture={(e: SyntheticEvent) => {
						if (e.target instanceof HTMLElement && e.target.nodeName === "INPUT") {
							e.stopPropagation();
						}
					}}
					getOptionLabel={(option) =>
						option?.accountNumber && option?.accountName
							? `${option.accountNumber} - ${option.accountName}`
							: (option?.accountNumber ?? "")
					}
					isLoading={isAccountsLoading}
					onChange={handleAccountChange}
					value={currentConfiguration?.accountNumber ?? ""}
					isError={
						!isAccountsLoading &&
						Boolean(accounts) &&
						(!accounts || accounts.length === 0 || currentConfiguration?.accountNumber === null)
					}
					errorText={
						!isAccountsLoading && (!accounts || accounts.length === 0)
							? noAccountText
							: NewOrderSelectAccountError
					}
					label={SelectAccountText}
					dataTestId="newOrder-select-account"
					required
					disableClearable
				/>
			</FormControl>

			<FormControl
				size="small"
				required
				fullWidth
				sx={{ alignSelf: "flex-start" }}
			>
				<AutoComplete
					key={currentConfiguration?.billToNumber?.billToId ?? ""}
					options={currentConfiguration?.accountNumber?.billTos ?? []}
					onMouseDownCapture={handleMouseDownCapture}
					getOptionLabel={(option: BillToInfoViewModel) => {
						const projectName =
							option.projectName && option.projectName.trim() !== "" ? option.projectName + " - " : "";
						const logisticsMode = option.logisticsMode
							? " - " + convertToTitleCase(option.logisticsMode)
							: "";
						return option
							? `${projectName}${option.city}, ${option.state}${logisticsMode} - ${option.billToId}`
							: "";
					}}
					isLoading={isAccountsLoading}
					onChange={handleBillToChange}
					value={currentConfiguration?.billToNumber ?? ""}
					isError={currentConfiguration?.billToNumber === null}
					errorText={NewOrderSelectBillToError}
					label={SelectBillToText}
					dataTestId="newOrder-select-billTo"
					PopperComponent={CustomPopper}
					required
					disableClearable
				/>
			</FormControl>

			{updateButtons && (
				<>
					<GlobalFormProvider isUpdate>
						<AddGlobalAttributes
							setConfigurationToUpdate={setConfigurationToUpdate}
							handleConfigurationUpdate={handleConfigurationUpdate}
							isUpdate
						/>
					</GlobalFormProvider>
					<Button
						onClick={handleRemoveGroup}
						variant="text"
						data-testid="remove-group"
					>
						<DeleteIcon />
						{NewOrderRemoveGroup}
					</Button>
				</>
			)}
		</AccountBillToContentWrapper>
	);
};

export default AccountBillToContent;
