import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "../data/api/v1/endpoints";
import { baseQueryWithRedirect } from "./apiUtils";
import { BillToShipTosAccountViewModel } from "data/api/v1";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

interface BillToShipToParams {
	accountId: string;
	billToId: string;
}
export const accountApiSlice = createApi({
	reducerPath: "accountApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getAccounts: builder.query<CustomerAccountViewModel[], string | null>({
			query: (productLineCode?: string | null) => endpoints.accounts.getAccounts(productLineCode),
			transformResponse: (response: { data: CustomerAccountViewModel[] }) => response?.data
		}),
		getAccountsById: builder.query<Array<CustomerAccountViewModel>, string>({
			query: (orderId: string) => endpoints.accounts.getAccountsByOrderId(orderId),
			transformResponse: (response: any) => response?.data
		}),
		getBillToShipTos: builder.query<BillToShipTosAccountViewModel[], object>({
			query: (args: BillToShipToParams) => {
				const { accountId, billToId } = args;
				return {
					url: endpoints.accounts.getBillToShipTos(accountId, billToId)
				};
			},
			transformResponse: (response: { data: BillToShipTosAccountViewModel[] }) => response?.data,
			transformErrorResponse: (errors: any) => ({
				data: {
					code: errors?.code
				}
			})
		}),
		getBillToInfo: builder.query<BillToInfoViewModel, object>({
			query: (args: BillToShipToParams) => {
				const { accountId, billToId } = args;
				return {
					url: endpoints.accounts.getBillToInfo(accountId, billToId)
				};
			},
			transformResponse: (response: { data: BillToInfoViewModel }) => response?.data,
			transformErrorResponse: (errors: any) => ({
				data: {
					code: errors?.code
				}
			})
		})
	})
});

export const {
	useGetAccountsQuery,
	useLazyGetAccountsQuery,
	useGetAccountsByIdQuery,
	useGetBillToShipTosQuery,
	useGetBillToInfoQuery
} = accountApiSlice;
