import {
	NewOrdersLayoutContainer,
	NewOrdersContentContainer,
	NewOrderFlowButtons,
	NewOrdersContent
} from "components/NewOrders/NewOrders.styles";
import {
	NewOrderBack,
	NewOrderContactCare,
	NewOrderGenericError,
	NewOrderNotCorrect,
	NewOrderProcessError,
	NewOrderReviewPageTitle,
	NewOrderSubmitOrder
} from "components/NewOrders/constants";
import NewOrdersSubheader from "components/NewOrders/NewOrdersSubheader/NewOrdersSubheader";
import ReviewOrderDetails from "components/NewOrders/Review/ReviewOrderDetails";
import ReviewOrderList from "components/NewOrders/Review/ReviewOrderList";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { type FC, RefObject, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {
	CreatePendingOrderRequest,
	CreatePendingOrderRequestPendingLineItemViewModel,
	CreatePendingOrderRequestPendingOrderConfigurationViewModel
} from "data/api/v1";
import {
	ViewPriceEstimatesBaseItem,
	ViewPriceEstimatesConfigurationModel
} from "components/NewOrders/ViewPriceEstimates/view-price-estimates-model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useCreateOrderMutation } from "features/newOrderApi";
import LoadingSkeleton from "pages/OrderPages/ReviewOrderPage/LoadingSkeleton/LoadingSkeleton";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { useDeleteDraftOrderMutation } from "features/orderApi";
import { ReviewNewOrderErrorBannerStyles } from "components/Replacements/ErrorBannerStyles";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

interface CombinedPendingOrderConfigurationViewModelLineItems {
	lineItems:
		| (
				| (CreatePendingOrderRequestPendingLineItemViewModel & {
						modifications: ViewPriceEstimatesBaseItem[] | undefined;
				  })[]
				| null
		  )
		| undefined;
}
type CombinedPendingOrderConfigurationViewModel = Omit<
	CreatePendingOrderRequestPendingOrderConfigurationViewModel,
	"lineItems"
> &
	CombinedPendingOrderConfigurationViewModelLineItems;
export interface NewOrdersForReview extends CombinedPendingOrderConfigurationViewModel {
	priceEstimateConfig: ViewPriceEstimatesConfigurationModel | undefined;
}

const ReviewOrderPage: FC = () => {
	const navigate = useNavigate();
	const [submitOrder, { error, isLoading }] = useCreateOrderMutation();
	const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [deleteDraftOrder] = useDeleteDraftOrderMutation();
	const draftId = useSelector((state: RootState) => state.submittingNewOrder.draftOrder?.draftOrderId);
	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);

	const newOrderDetails: CreatePendingOrderRequest | undefined = useSelector(
		(state: RootState) => state.submittingNewOrder.newOrder
	);

	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};

	const handleBack = () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		navigate("/new-order/shipping-details");
	};

	const encodeBase64 = (obj: CreatePendingOrderRequest): CreatePendingOrderRequest => {
		return {
			...obj,
			poNumber: btoa(obj?.poNumber ?? ""),
			jobName: btoa(obj?.jobName ?? ""),
			labelComments: btoa(obj?.labelComments ?? ""),
			shipToAddress: {
				...obj.shipToAddress,
				name: btoa(obj?.shipToAddress?.name ?? ""),
				line1: btoa(obj.shipToAddress?.line1 ?? ""),
				line2: btoa(obj.shipToAddress?.line2 ?? ""),
				phoneNumber: btoa(obj.shipToAddress?.phoneNumber ?? ""),
				email: btoa(obj.shipToAddress?.email ?? "")
			}
		};
	};

	const handleContinue = async () => {
		setShowSkeleton(true);
		let encodedNewOrder;
		if (newOrderDetails) {
			encodedNewOrder = encodeBase64(newOrderDetails);
		}
		await submitOrder(encodedNewOrder)
			.unwrap()
			.then(() => {
				navigate("/new-order/confirmation");
				if (draftId) {
					deleteDraftOrder(draftId ?? "");
				}
				dispatch(submittingNewOrderActions.clearDraftError());
			})
			.catch(() => setShowSkeleton(false));
	};

	if (isLoading || showSkeleton) {
		return <LoadingSkeleton />;
	}

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				draftOrder={draftOrder}
				getContainerElement={calcHeightOfHeaderForContentTopOffset}
				isReview
				isPDFExport
			/>
			<NewOrdersContentContainer marginTopOffset={contentOffset}>
				<NewOrdersContent>
					<NewOrdersSubheader
						title={NewOrderReviewPageTitle}
						dataTestId="new-order-review-order-label"
						handleSearch={() => {}}
						draftOrder={draftOrder}
					/>
					{error && (
						<ReviewNewOrderErrorBannerStyles data-testid="api-error-banner-header">
							<div>
								<div>
									<ErrorOutline />
								</div>
								<div>
									<h6>{NewOrderProcessError}</h6>
									<span>{NewOrderNotCorrect}</span>
									<ul>
										<li>
											{"errors" in error &&
											Array.isArray(error.errors) &&
											error.errors[0]?.message
												? error.errors[0].message
												: NewOrderGenericError}
										</li>
									</ul>
									<span>{NewOrderContactCare}</span>
									{"status" in error && error.status && <span>({error.status} Error)</span>}
								</div>
							</div>
						</ReviewNewOrderErrorBannerStyles>
					)}
					<ReviewOrderDetails />
					<ReviewOrderList />
					<NewOrderFlowButtons>
						<Button
							data-testid="new-order-review-back-button"
							variant="text"
							onClick={handleBack}
						>
							{NewOrderBack}
						</Button>
						<Button
							data-testid="new-order-review-submit-order-button"
							onClick={handleContinue}
							variant="contained"
						>
							{NewOrderSubmitOrder}
						</Button>
					</NewOrderFlowButtons>
				</NewOrdersContent>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default ReviewOrderPage;
