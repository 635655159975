import {
	BuildNewOrderLineItemsContainer,
	BuildNewOrderLineItemsFlexbox,
	BuildNewOrderLineItemsHeader,
	BuildNewOrderLineItemsHeaderWrapper,
	NewOrderFlowButtons
} from "components/NewOrders/NewOrders.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { Fragment, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button } from "@mui/material";
import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderBuildOrderLabel,
	NewOrderCancelButton,
	NewOrderConfirmRemoveGroup,
	NewOrderDialogSubtitle,
	NewOrderDialogTitle,
	NewOrderEdit,
	NewOrderEmptyBullet1,
	NewOrderEmptyBullet2,
	NewOrderPriceEstimate,
	NewOrderRemoveButton,
	NewOrderRemoveGroupSubtitle,
	NewOrderRemoveGroupTitle,
	NewOrderRemoveItem,
	NewOrderSubtitle
} from "components/NewOrders/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import { LineItemAccordionDetails } from "components/Common/LineItemGrid/LineItemGridStyles";
import { useValidateOrderRequestMutation } from "features/newOrderApi";
import { FileContents, newOrderActions } from "features/newOrder";
import { BuildLineItemGrid } from "components/NewOrders/BuildNewOrderLineItems/buildNewOrderLineItems.styles";
import Header from "components/Common/LineItemGrid/Header/Header";
import Modification from "components/Common/LineItemGrid/Modification/Modification";
import { EmptyFlyoutHeaderText, LineItemsInOrderText } from "constants/text";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import AccountBillToContent from "../AccountBillToContent";
import { convertItemsToDraft, useCheckForUpdatedItemsBillToAccountErrors } from "../utils/NewOrderUtils";
import NewOrdersSubheader from "../NewOrdersSubheader/NewOrdersSubheader";
import { NewOrderLabelSkeleton } from "../EstimatePageSkeletons/estimateLoadingSkeleton.styles";
import EstimateHeaderLoadingSkeleton from "../EstimatePageSkeletons/EstimateHeaderLoadingSkeleton";
import ErrorBanner from "../../Replacements/ErrorBanner";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import LeaveFlowDialog from "../../Common/LeaveFlowDialog/LeaveFlowDialog";
import { ImportItemViewModel } from "data/api/v1/model/import-item-view-model";
import { CustomerAccountViewModel, ImportConfigurationViewModel } from "data/api/v1";
import { LinkButton } from "../../Common/Link";
import DefaultMessage from "../../Common/DefaultMessage/DefaultMessage";
import { shippingDetailsActions } from "features/shippingDetails";
import AddIcon from "@mui/icons-material/Add";
import AddNewLineItem from "../AddLineItem/AddLineItem.tsx";
import { AddItemButton } from "../AddLineItem/AddLineItemStyles.ts";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model.ts";
import { updateOrderDetails } from "pages/OrderPages/utils/OrderDetailUtil";
import { AddLineAddLineButtonText } from "../AddLineItem/constants.ts";

interface Props {
	draftOrder?: DraftOrderViewModel;
}

const BuildNewOrderLineItems = ({ draftOrder }: Props) => {
	const navigateTo = useNavigate();
	const dispatch = useDispatch();
	const [validateOrderRequest, { isLoading }] = useValidateOrderRequestMutation();
	const [lineItemErrors, setLineItemErrors] = useState<(string | undefined | null)[][]>([]);
	const [showErrorBanner, setShowErrorBanner] = useState(false);
	const parsedCSV: ImportOrderViewModel | undefined = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const newGlobalAttribute: ImportConfigurationViewModel | undefined = useSelector(
		(state: RootState) => state.newOrder.newGlobalAttributes
	);
	const csvConfigurations = useMemo(() => {
		return parsedCSV?.configurations?.map((config) => config ?? []);
	}, [parsedCSV]);
	const [updatedItems, setUpdatedItems] = useState<ImportConfigurationViewModel[] | undefined>(csvConfigurations);
	const selectedRemovedItem = useRef<ImportItemViewModel | undefined>();
	const selectedRemovedConfiguration = useRef<ImportConfigurationViewModel | undefined>();
	const [showAddItemButton, setShowAddItemButton] = useState<number | undefined>(undefined);
	const [showEstimateButton, setShowEstimateButton] = useState(true);

	const toggleAddItem = (index?: number) => {
		setShowAddItemButton(index);
		setShowEstimateButton((prev) => !prev);
	};

	const handleUpdateAccount = (account: CustomerAccountViewModel | null, index: number) => {
		setUpdatedItems((prev) =>
			prev?.map((item, idx) => {
				if (idx === index) {
					return { ...item, accountNumber: account };
				}
				return item;
			})
		);
	};
	const handleUpdateBillTo = (billTo: BillToInfoViewModel | undefined, index: number) => {
		setUpdatedItems((prev) =>
			prev?.map((item, idx) => {
				if (idx === index) {
					return { ...item, billToNumber: billTo };
				}
				return item;
			})
		);
	};

	const handleConfigurationUpdate = (configuration: ImportConfigurationViewModel, index: number) => {
		setUpdatedItems((prev) =>
			prev?.map((item, idx) => {
				if (idx === index) {
					return { ...item, ...configuration };
				}
				return item;
			})
		);
	};

	const setConfigurationToUpdate = (configuration: ImportConfigurationViewModel) => {
		dispatch(newOrderActions.updateGlobalAttributes(configuration));
	};

	useEffect(() => {
		if (newGlobalAttribute) {
			setUpdatedItems((prev) => {
				if (!prev?.find((config) => JSON.stringify(config) === JSON.stringify(newGlobalAttribute))) {
					return shuffleLineItems([...(prev ?? []), newGlobalAttribute]);
				}
				return prev;
			});
		}
	}, [newGlobalAttribute]);

	const handleGeneratePriceEstimate = async () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		setTimeout(() => window.scrollTo(0, 0), 0);
		const apiInput = updatedItems?.map((config) => {
			const lineItems = config.items.flatMap((lineItem) => {
				const parentLineItem = {
					sku: lineItem.userCode,
					optionCode: null,
					optionValue: null,
					quantity: lineItem.quantity,
					lineNumber: lineItem.lineNumber
				};
				if (lineItem?.modifications && lineItem.modifications?.length > 0) {
					const modLineItems = lineItem.modifications.map((mod) => {
						return {
							sku: mod.userCode,
							optionCode: mod.userCode,
							// NOTE: This will be another field to use for optionvalue but still getting info on how to parse in backend
							optionValue: mod.userCode,
							quantity: mod.quantity,
							lineNumber: mod.lineNumber
						};
					});
					return [parentLineItem, ...modLineItems];
				}
				return parentLineItem;
			});

			return {
				accountId: config.accountNumber?.accountId,
				billTo: config?.billToNumber?.billToId,
				parentBrand: config?.parentBrand,
				productLineCode: config?.productLineCode,
				styleCode: config?.doorStyleCode,
				finishCode: config?.finishCode,
				constructionCode: config?.constructionCode,
				packagingCode: config?.packagingCode,
				speciesCode: config?.speciesCode,
				shapeCode: config?.shapeCode,
				lineItems: lineItems
			};
		});

		try {
			await validateOrderRequest(apiInput)
				.unwrap()
				.then(async (response) => {
					dispatch(newOrderActions.addValidatedOrder(response));
					const foundConfigError = response?.configurations?.find((config) => config.errorMessage);
					let hasError = false;
					const foundLineItemError = response?.configurations?.map((config) =>
						config.lineItems.map((lineItem) => {
							if (lineItem.errorMessage) {
								hasError = true;
							}
							return lineItem.errorMessage;
						})
					);

					if (foundConfigError || hasError) {
						setShowErrorBanner(true);
					}
					setLineItemErrors(foundLineItemError);

					if (!foundConfigError && !hasError) {
						dispatch(newOrderActions.addParsedCSV({ ...parsedCSV, configurations: updatedItems }));
						updateOrderDetails(updatedItems, dispatch, draftOrder);
						navigateTo("/new-order/view-price-estimates");
					}
				});
		} catch (error: any) {
			if (error.status === 400) {
				return setShowErrorBanner(true);
			}

			if (error.status === 401) {
				return navigateTo("/unauthorized");
			}

			if (error.status >= 500) {
				return navigateTo("/unavailable");
			}
		}
	};

	const [searchValue, setSearchValue] = useState<string | null>("");
	const [searchOptions, setSearchOptions] = useState<string[]>([]);
	const [removeItem, setRemoveItem] = useState(false);

	useEffect(() => {
		updatedItems?.forEach((config) => {
			setSearchOptions((prev) =>
				prev.concat(config.items.map((item) => `${item.userCode} - ${item.description}`))
			);
		});
	}, [updatedItems]);

	const handleLineItemSearch = (_event: SyntheticEvent, values: string | null) => {
		const foundSearch = searchOptions.find((option) =>
			option.toUpperCase().includes((values as string).toUpperCase())
		);
		setSearchValue(foundSearch ? values : "");
	};

	const handleOpenRemoveModal = (item: ImportItemViewModel) => {
		setRemoveItem(true);
		selectedRemovedItem.current = item;
	};

	const handleConfirm = () => {
		getUpdatedItems();
		selectedRemovedItem.current = undefined;
		setRemoveItem(false);
	};

	const noLineItems = useMemo(() => {
		return updatedItems?.length === 0;
	}, [updatedItems]);

	const generatePrice = useCheckForUpdatedItemsBillToAccountErrors(
		handleGeneratePriceEstimate,
		{ ...parsedCSV, configurations: updatedItems ?? null } as FileContents,
		handleUpdateAccount,
		handleUpdateBillTo
	);
	const dialogState = useSelector((state: RootState) => state.newOrder.leaveNewOrderFlow);

	if (dialogState?.state === "pause" && noLineItems) {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(newOrderActions.resetNewOrderFlow());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
		navigateTo("/new-order");
	}

	const shuffleLineItems = (configurations: ImportConfigurationViewModel[]): ImportConfigurationViewModel[] => {
		let lineItemNumber = 1;
		return configurations.map((config) => ({
			...config,
			items: config.items
				.filter((configItem) => configItem.lineNumber !== selectedRemovedItem?.current?.lineNumber)
				.map((item) => {
					const newModifications = item.modifications?.map((modification, index) => ({
						...modification,
						lineNumber: `${lineItemNumber}.${index + 1}`
					}));
					const newLineItem = {
						...item,
						lineNumber: String(lineItemNumber),
						modifications: newModifications ?? null
					};
					lineItemNumber += 1;
					return newLineItem;
				})
		}));
	};

	useEffect(() => {
		dispatch(
			submittingNewOrderActions.updateDraftOrder({
				...draftOrder,
				configurations: convertItemsToDraft(updatedItems, draftOrder)
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedItems, dispatch]);

	const getUpdatedItems = () => {
		setUpdatedItems((prevState) => {
			if (prevState) {
				return shuffleLineItems(prevState);
			}
		});
	};

	const [removeGroup, setRemoveGroup] = useState(false);

	const handleRemoveGroup = (config: ImportConfigurationViewModel) => {
		setRemoveGroup(true);
		selectedRemovedConfiguration.current = config;
	};

	const handleDialogClose = () => {
		selectedRemovedConfiguration.current = undefined;
		selectedRemovedItem.current = undefined;
		setRemoveItem(false);
		setRemoveGroup(false);
	};

	const getUpdatedConfigs = () => {
		const newUpdatedConfigs = updatedItems?.filter(
			(configuration) => configuration !== selectedRemovedConfiguration?.current
		);
		if (newUpdatedConfigs) setUpdatedItems(shuffleLineItems(newUpdatedConfigs));
	};

	const handleGlobalConfirm = () => {
		getUpdatedConfigs();
		setRemoveGroup(false);
		selectedRemovedConfiguration.current = undefined;
	};

	const getAttributes = (configuration: ImportConfigurationViewModel) => {
		return [configuration?.productLine, configuration?.doorStyle, configuration?.species, configuration?.finish]
			.filter(Boolean)
			.join(", ");
	};

	const getSubheaderAttributes = (configuration: ImportConfigurationViewModel) => {
		return [configuration?.shape, configuration?.construction, configuration?.packaging].filter(Boolean).join(", ");
	};

	const handleLineItem = (configIndex: number, newLineItem: ImportItemViewModel) => {
		setUpdatedItems((prev) => {
			if (prev) {
				const workingCopy = prev?.slice(0);
				const workingConfig = workingCopy?.[configIndex];
				const config = { ...workingConfig, items: [...workingConfig.items, newLineItem] };
				workingCopy[configIndex] = config;
				return workingCopy;
			}
			return prev;
		});

		getUpdatedItems();
	};

	return (
		<BuildNewOrderLineItemsContainer>
			<NewOrderLabelSkeleton isLoading={isLoading} />
			<NewOrdersSubheader
				isLoading={isLoading}
				title={NewOrderBuildOrderLabel}
				dataTestId="new-order-build-label-header"
				hasSearch
				handleSearch={(event, values) => handleLineItemSearch(event, (values as string) ?? "")}
				searchOptions={searchOptions}
				searchValue={searchValue ?? ""}
				autoCompleteId="build-new-order-line-items-search"
				draftOrder={draftOrder}
				hasAddGlobalAttributesButton
			/>
			{showErrorBanner && (
				<ErrorBanner
					isLoading={isLoading}
					newOrderLineItemErrors
				/>
			)}
			{noLineItems || !updatedItems ? (
				<DefaultMessage
					dataTestId="default-message-empty-order"
					asset="/assets/NoOrdersSadBoxImage.svg"
					altMessage="Default message no line items"
					title={EmptyFlyoutHeaderText}
					subtitle={NewOrderSubtitle}
					bullets={[NewOrderEmptyBullet1, NewOrderEmptyBullet2]}
					removeHeight
				/>
			) : undefined}
			{(removeGroup || removeItem) && (
				<LeaveFlowDialog
					dialogState={removeGroup || removeItem}
					firstButtonText={NewOrderCancelButton.toUpperCase()}
					handleOpenDraftModal={(removeGroup && handleGlobalConfirm) || handleConfirm}
					handleDialogClose={handleDialogClose}
					handleDialogExitOrder={handleDialogClose}
					secondButtonText={(removeGroup && NewOrderConfirmRemoveGroup) || NewOrderRemoveItem}
					subtitle={(removeGroup && NewOrderRemoveGroupSubtitle) || NewOrderDialogSubtitle}
					title={(removeGroup && NewOrderRemoveGroupTitle) || NewOrderDialogTitle}
				/>
			)}
			<BuildNewOrderLineItemsFlexbox>
				{Array.isArray(updatedItems)
					? updatedItems.map((configuration, configIndex) => {
							return (
								<Fragment key={JSON.stringify(configuration)}>
									<Accordion
										defaultExpanded
										key={configuration.doorStyleCode}
									>
										<BuildNewOrderLineItemsHeaderWrapper
											isLoading={isLoading}
											expandIcon={<ExpandMoreIcon />}
										>
											<BuildNewOrderLineItemsHeader>
												<div data-testid="new-order-build-label">
													<div>{getAttributes(configuration)}</div>
													<div>{getSubheaderAttributes(configuration)}</div>
												</div>
												<div data-testid="new-order-build-table-count">
													{configuration?.items.length} {LineItemsInOrderText}
												</div>
											</BuildNewOrderLineItemsHeader>
										</BuildNewOrderLineItemsHeaderWrapper>
										<EstimateHeaderLoadingSkeleton isLoading={isLoading} />
										{!isLoading && (
											<AccountBillToContent
												handleUpdateAccount={handleUpdateAccount}
												handleUpdateBillTo={handleUpdateBillTo}
												csvContents={
													{ ...parsedCSV, configurations: updatedItems } as FileContents
												}
												index={configIndex}
												isBuildOrderPage
												handleRemoveGroup={() => handleRemoveGroup(configuration)}
												updateButtons
												handleConfigurationUpdate={(
													configuration: ImportConfigurationViewModel
												) => handleConfigurationUpdate(configuration, configIndex)}
												setConfigurationToUpdate={() => setConfigurationToUpdate(configuration)}
											/>
										)}
										<LineItemAccordionDetails>
											{configuration?.items?.map((item, itemIndex) => {
												const foundSearch = `${item.userCode} - ${item.description}`
													.toUpperCase()
													.includes((searchValue as string).toUpperCase());
												if (foundSearch) {
													return (
														<BuildLineItemGrid
															isLoading={isLoading}
															key={`${item.userCode}-${item.lineNumber}`}
															index={itemIndex}
															error={lineItemErrors[configIndex]?.[itemIndex]}
															mainRowContent={[
																<Header
																	description={item?.description ?? ""}
																	key={`${item.userCode}-${item.lineNumber}-header`}
																	header={item?.userCode ?? ""}
																	lineNumber={item?.lineNumber ?? ""}
																/>,
																<div
																	key={`${item.userCode}-${item.lineNumber}-empty`}
																/>,
																<div
																	key={`${item.userCode}-${item.lineNumber}-door-style`}
																>
																	<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
																	<div data-testid="line-item-grid-data-door-style">
																		{configuration.doorStyle}
																	</div>
																</div>,
																<div key={`${item.userCode}-${item.lineNumber}-finish`}>
																	<div>{NewOrderBuildGridFinishColumnHeader}</div>
																	<div data-testid="line-item-grid-data-finish">
																		{configuration.finish}
																	</div>
																</div>,
																<div key={`${item.userCode}-${item.lineNumber}-qty`}>
																	<div>{NewOrderBuildGridQuantityColumnHeader}</div>
																	<div data-testid="line-item-grid-data-quantity">
																		{item.quantity}
																	</div>
																</div>,
																// if this isn't inline the styles don't get applied
																<div
																	style={{
																		flexDirection: "column",
																		gap: "unset"
																	}}
																	key={`${item.userCode}-${item.lineNumber}-remove`}
																>
																	<LinkButton
																		onClick={() => {}}
																		data-testid="line-item-edit-link"
																	>
																		{NewOrderEdit}
																	</LinkButton>

																	<LinkButton
																		variant="remove"
																		onClick={() => handleOpenRemoveModal(item)}
																		data-testid="line-item-remove-link"
																	>
																		{NewOrderRemoveButton}
																	</LinkButton>
																</div>
															]}
															modificationRows={item.modifications?.map(
																(modification: any) => (
																	<Modification
																		description={modification.description}
																		key={modification.userCode}
																		extendedPrice={modification.extendedPrice}
																		lineNumber={modification.lineNumber}
																		listPrice={modification.listPrice}
																		title={modification.userCode}
																	/>
																)
															)}
														/>
													);
												} else {
													return <></>;
												}
											})}
										</LineItemAccordionDetails>
										{configIndex !== showAddItemButton ? (
											<AddItemButton
												variant="text"
												onClick={() => toggleAddItem(configIndex)}
												data-testid="add-item-button"
											>
												<AddIcon />
												{AddLineAddLineButtonText}
											</AddItemButton>
										) : (
											<AddNewLineItem
												handleLineItem={handleLineItem}
												index={configIndex}
												configuration={configuration}
												addItemVisibility={toggleAddItem}
											/>
										)}
									</Accordion>
								</Fragment>
							);
						})
					: undefined}
			</BuildNewOrderLineItemsFlexbox>
			{!noLineItems && noLineItems !== undefined && showEstimateButton && (
				<NewOrderFlowButtons isLoading={isLoading}>
					<Button
						data-testid="new-order-build-continue-button"
						variant="contained"
						onClick={generatePrice}
					>
						{NewOrderPriceEstimate}
					</Button>
				</NewOrderFlowButtons>
			)}
		</BuildNewOrderLineItemsContainer>
	);
};

export default BuildNewOrderLineItems;
