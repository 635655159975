import { NewOrderModalTracking, ImportConfigurationViewModel } from "data/api/v1";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { read } from "hooks/useSession";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import { OrderValidationViewModel } from "data/api/v1/model/order-validation-view-model";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";
import { UserNavigationItemViewModel } from "data/api/v1/model/user-navigation-item-view-model";

export interface NewOrder {
	fileName: string | null;
	configurations: Array<ImportConfigurationViewModel> | null;
	fileUpload: Array<File>;
}

export interface FileContents {
	fileName: string | null;
	configurations: Array<ImportConfigurationViewModel> | null;
}

export interface LinkNavigation {
	navItem?: UserNavigationItemViewModel;
	index?: number;
	state: "proceed" | "pause";
}

const newOrder = read("_newOrder");

interface NewOrderSlice {
	leaveNewOrderFlow: LinkNavigation | undefined;
	modalStatus: NewOrderModalTracking;
	newAddresses: ShipToViewModel[];
	parsedCSV: ImportOrderViewModel | undefined;
	validatedOrderResponse: OrderValidationViewModel | undefined;
	newGlobalAttributes: ImportConfigurationViewModel | undefined;
	updatedGlobalAttributes: ImportConfigurationViewModel | undefined;
}
const initialState: NewOrderSlice = newOrder || {
	leaveNewOrderFlow: undefined,
	modalStatus: NewOrderModalTracking.NONE,
	newAddresses: [],
	parsedCSV: undefined,
	validatedOrderResponse: undefined,
	newGlobalAttribute: undefined,
	updatedGlobalAttributes: undefined
};

const newOrderSlice = createSlice({
	name: "newOrder",
	initialState,
	reducers: {
		addNewAddress: (state, action) => {
			const addressMatchCallback = (shipTo: ShipToViewModel | null) => {
				return (
					shipTo?.address?.line1 === action.payload?.line1 &&
					shipTo?.address?.line2 === action.payload?.line2 &&
					shipTo?.address?.line3 === action.payload?.line3 &&
					shipTo?.address?.city === action.payload?.city &&
					shipTo?.address?.zip === action.payload?.zip
				);
			};

			if (action.payload && !state.newAddresses?.some(addressMatchCallback)) {
				state.newAddresses?.push(action.payload);
			}

			return state;
		},
		setModalState: (state, action) => {
			state.modalStatus = action.payload;
		},
		addParsedCSV: (state, action) => {
			state.parsedCSV = action.payload;
		},
		addGlobalAttributes: (state, action) => {
			state.newGlobalAttributes = action.payload;
		},
		updateGlobalAttributes: (state, action) => {
			state.updatedGlobalAttributes = { ...state.updatedGlobalAttributes, ...action.payload };
		},
		clearUpdateGlobalAttributes: (state) => {
			state.updatedGlobalAttributes = undefined;
		},
		updateAccountNumber: (
			state,
			action: PayloadAction<{ index: number | undefined; newAccountNumber: CustomerAccountViewModel | null }>
		) => {
			const { index, newAccountNumber } = action.payload;
			if (index !== undefined && state.parsedCSV?.configurations) {
				const configuration = state.parsedCSV.configurations[index];
				if (configuration) {
					configuration.accountNumber = newAccountNumber;
				}
			}
		},
		updateBillToNumber: (
			state,
			action: PayloadAction<{
				index: number | undefined;
				newBillToNumber: BillToInfoViewModel | undefined | null;
			}>
		) => {
			const { index, newBillToNumber } = action.payload;
			if (index !== undefined && state.parsedCSV?.configurations) {
				const configuration = state.parsedCSV.configurations[index];
				if (configuration) {
					configuration.billToNumber = newBillToNumber;
				}
			}
		},
		addValidatedOrder: (state, action) => {
			state.validatedOrderResponse = action.payload;
		},
		clearNewOrder: (state) => {
			state.modalStatus = NewOrderModalTracking.NONE;
			state.newAddresses = [];
			state.parsedCSV = undefined;
			state.validatedOrderResponse = undefined;
			state.newGlobalAttributes = undefined;
			state.updatedGlobalAttributes = undefined;
		},
		leaveNewOrderFlow: (state, action) => {
			state.leaveNewOrderFlow = action.payload;
		},
		continueNewOrderFlow: (state) => {
			if (state.leaveNewOrderFlow) {
				state.leaveNewOrderFlow = { ...state.leaveNewOrderFlow, state: "proceed" };
			}
		},
		resetNewOrderFlow: (state) => {
			state.leaveNewOrderFlow = undefined;
		}
	}
});

export const newOrderActions = newOrderSlice.actions;
export default newOrderSlice.reducer;
