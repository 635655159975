import { NewOrdersSubheaderWrapper, SaveDraftErrorBanner } from "../NewOrders.styles";
import { SearchItemText } from "constants/text";
import { SyntheticEvent } from "react";
import SaveDraft from "../SaveDraft/SaveDraft";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { DraftErrorTitle } from "../constants";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import AddGlobalAttributes from "../AddGlobalAttributes/AddGlobalAttributes";
import GlobalFormProvider from "../AddGlobalAttributes/forms/GlobalFormProvider";

interface NewOrdersSubheaderProps {
	title: string;
	dataTestId: string;
	hasSearch?: boolean;
	handleSearch: (event: SyntheticEvent, values: string | null) => void;
	searchOptions?: Array<string>;
	searchValue?: string;
	autoCompleteId?: string;
	isLoading?: boolean;
	draftOrder: DraftOrderViewModel | undefined;
	hasAddGlobalAttributesButton?: boolean;
}

const NewOrdersSubheader = ({
	title,
	dataTestId,
	hasSearch = false,
	handleSearch,
	isLoading,
	searchOptions,
	searchValue,
	autoCompleteId,
	draftOrder,
	hasAddGlobalAttributesButton = false
}: NewOrdersSubheaderProps) => {
	const isDraftError = useSelector((state: RootState) => state.submittingNewOrder.draftError);
	return (
		<NewOrdersSubheaderWrapper
			isLoading={isLoading}
			data-testid={dataTestId}
		>
			<div>
				<h1>{title}</h1>
				<div>
					{hasAddGlobalAttributesButton && (
						<GlobalFormProvider>
							<AddGlobalAttributes />
						</GlobalFormProvider>
					)}
					<SaveDraft draftOrder={draftOrder} />
					{hasSearch && (
						<SuggestedSearch
							ariaLabel={autoCompleteId ?? ""}
							dataTestId={autoCompleteId ?? ""}
							dropDownOpenOnChar={3}
							placeholder={SearchItemText}
							handleSearch={handleSearch}
							id={autoCompleteId ?? ""}
							searchOptions={searchOptions ?? []}
							searchValue={searchValue ?? ""}
							size="small"
						/>
					)}
				</div>
			</div>
			{isDraftError && (
				<SaveDraftErrorBanner>
					<ErrorOutline />
					<div>
						<h6>{DraftErrorTitle}</h6>
						<div>
							<p>
								The server is temporarily unable to save your draft. Please try again shortly by
								refreshing your page or&nbsp;
								<a
									href="https://tandem.cabinetworksgroup.com/s/tandem-support"
									target="_blank"
									rel="noreferrer"
								>
									Submit a Tandem Support Request
								</a>
								.
								<a
									href="https://tandem.cabinetworksgroup.com/s/tandem-support"
									target="_blank"
									rel="noreferrer"
								>
									<OpenInNew />
								</a>
							</p>
						</div>
					</div>
				</SaveDraftErrorBanner>
			)}
		</NewOrdersSubheaderWrapper>
	);
};

export default NewOrdersSubheader;
