import { Autocomplete, CircularProgress, FormHelperText, PopperProps } from "@mui/material";
import { ReactNode, SyntheticEvent } from "react";
import { AutoCompletePopper } from "./Autocomplete.styles";
import HelperText from "components/Form/HelperText/HelperText";
import TextField from "@mui/material/TextField";

interface AutoCompleteProps {
	options: any[];
	onMouseDownCapture?: (e: SyntheticEvent) => void;
	getOptionLabel?: (option: any) => string;
	isLoading?: boolean;
	onChange: (_event: SyntheticEvent, value: any) => void;
	defaultValue?: any;
	value?: any;
	isError: boolean;
	errorText: string | undefined;
	label: string;
	dataTestId: string;
	required?: boolean;
	disableClearable?: boolean;
	disabled?: boolean;
	PopperComponent?: (props: PopperProps) => ReactNode | null;
}

const AutoComplete = ({
	options,
	onMouseDownCapture,
	getOptionLabel,
	isLoading,
	onChange,
	defaultValue,
	value,
	isError,
	errorText,
	label,
	dataTestId,
	required,
	disableClearable,
	disabled,
	PopperComponent
}: AutoCompleteProps) => {
	const CustomPopper = (props: PopperProps) => {
		return <AutoCompletePopper {...props} />;
	};

	return (
		<div>
			<Autocomplete
				options={options}
				size="small"
				disabled={disabled}
				onMouseDownCapture={onMouseDownCapture}
				getOptionLabel={getOptionLabel}
				loading={isLoading}
				onChange={onChange}
				isOptionEqualToValue={() => true}
				defaultValue={defaultValue}
				value={value}
				disableClearable={disableClearable}
				renderOption={(props, option) => {
					const optionLabel = getOptionLabel ? getOptionLabel(option) : option.toString();
					const uniqueKey = `${optionLabel}-${options.indexOf(option)}`;

					return (
						<li
							{...props}
							key={uniqueKey}
						>
							{optionLabel}
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						error={isError}
						label={label}
						data-testid={dataTestId}
						variant="outlined"
						required={required}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{isLoading ? (
										<CircularProgress
											color="inherit"
											size={20}
											data-testid="loading-spinner"
										/>
									) : null}
									{params.InputProps.endAdornment}
								</>
							)
						}}
					/>
				)}
				PopperComponent={PopperComponent ?? CustomPopper}
			/>
			{isError && errorText && (
				<FormHelperText error>
					<HelperText
						isError={true}
						text={errorText}
					/>
				</FormHelperText>
			)}
		</div>
	);
};

export default AutoComplete;
