import { StaticComponentBackground, StaticComponentTitle, SummaryProductLine } from "../NewOrders.styles";
import {
	NewOrderAccountNumber,
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderLineItemsInOrderText,
	NewOrderOrderListHeader,
	NewOrderSubtotalLabel,
	NewOrderViewPriceEstimateExtendedPriceColumnHeader,
	NewOrderViewPriceEstimateListPriceColumnHeader
} from "../constants";
import {
	ReviewOrderListHeader,
	ReviewOrderListLineItemContainer,
	ReviewOrderSubtotal,
	ReviewOrderAccountBillToCardContainer
} from "./reviewOrder.styles";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { FC, useMemo } from "react";
import {
	AccountBillToCard,
	AccountBillToCardHeader,
	AccountBillToCardDetailsContainer,
	AccountBillToCardDetails,
	EstimatesLineItemGrid
} from "../ViewPriceEstimates/viewPriceEstimates.styles";
import { brandCheck } from "utils/order";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import { UnavailableDataPlaceholderText } from "constants/text";
import Header from "../../Common/LineItemGrid/Header/Header";
import EstimatedSubtotal from "../ViewPriceEstimates/EstimatedSubtotal";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { OrderType } from "data/api/v1";
import { NewOrdersForReview } from "pages/OrderPages/ReviewOrderPage/ReviewOrderPage";
import { createViewPriceEstimates } from "components/NewOrders/utils/NewOrderUtils";
import Modification from "components/Common/LineItemGrid/Modification/Modification";
import { useLocation } from "react-router-dom";

const ReviewOrderList: FC = () => {
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const newOrderDetails = useSelector((state: RootState) => state.submittingNewOrder.reviewOrderDisplay);
	const location = useLocation();
	const priceEstimates = useMemo(() => {
		if (parsedCSV !== undefined && validatedOrderResponse !== undefined) {
			return createViewPriceEstimates(parsedCSV, validatedOrderResponse);
		}
	}, [parsedCSV, validatedOrderResponse]);

	const priceEstimateConfig = useMemo(() => {
		return priceEstimates?.configurations.map((priceEstimateConfiguration) => priceEstimateConfiguration);
	}, [priceEstimates]);

	const newOrderConfiguration = useMemo(() => {
		return newOrderDetails?.configurations?.map((config) => config);
	}, [newOrderDetails]);

	const newOrderForReview: NewOrdersForReview[] | undefined = useMemo(
		() =>
			newOrderConfiguration?.map((config, index) => ({
				...config,
				style: priceEstimateConfig?.[index]?.doorStyle ?? "",
				finish: priceEstimateConfig?.[index]?.finish ?? "",
				lineItems: config.lineItems?.map((item, i) => ({
					...item,
					doorStyle: priceEstimateConfig?.[index]?.doorStyle,
					finish: priceEstimateConfig?.[index]?.finish,
					modifications: priceEstimateConfig?.[index]?.items?.[i]?.modifications
				})),
				priceEstimateConfig: priceEstimateConfig?.[index]
			})),
		[newOrderConfiguration, priceEstimateConfig]
	);

	return (
		<>
			<StaticComponentBackground>
				<StaticComponentTitle data-testid="new-order-review-order-list-title">
					{NewOrderOrderListHeader}
				</StaticComponentTitle>
				{newOrderForReview?.map((config) => (
					<>
						<ReviewOrderListHeader key={config.accountId}>
							<span data-testid="new-order-build-label">
								<div>
									{config?.priceEstimateConfig?.productLine}, {config.style}, {config.species},{" "}
									{config.finish}
								</div>
								<div>
									{config?.priceEstimateConfig?.shape}, {config.construction}, {config.packaging}
								</div>
							</span>
							<span data-testid="new-order-review-order-list-line-items">
								{config?.lineItems?.length} {NewOrderLineItemsInOrderText}
							</span>
						</ReviewOrderListHeader>

						<ReviewOrderAccountBillToCardContainer>
							<AccountBillToCard>
								<AccountBillToCardHeader>{NewOrderAccountNumber}</AccountBillToCardHeader>
								<AccountBillToCardDetailsContainer>
									<AccountBillToCardDetails>
										<div data-testid="new-order-review-order-list-account-id">
											{config.priceEstimateConfig?.accountNumber?.accountNumber}
										</div>
										<div data-testid="new-order-review-order-list-account-name">
											{config.priceEstimateConfig?.accountNumber?.accountName}
										</div>
									</AccountBillToCardDetails>
									<div>
										<StyledSummaryCardLogo
											data-testid="new-order-review-order-list-brand-logo"
											parentBrand={config.priceEstimateConfig?.parentBrand}
											src={
												brandCheck(config.priceEstimateConfig?.parentBrand)
													? `/assets/manufacture_logos/${config.priceEstimateConfig?.parentBrand}.png`
													: "/assets/tandem_logos/cwg_logo.png"
											}
											alt={
												brandCheck(config.priceEstimateConfig?.parentBrand)
													? config.priceEstimateConfig?.parentBrand
													: "CabinetworksGroup Logo"
											}
										/>
										{(location.pathname == "/new-order/review-order" ||
											location.pathname == "/new-order/confirmation") && (
											<SummaryProductLine>
												{config.priceEstimateConfig?.productLine}
											</SummaryProductLine>
										)}
									</div>
								</AccountBillToCardDetailsContainer>
							</AccountBillToCard>
							<BillToBox
								billTo={config.priceEstimateConfig?.billToNumber}
								orderType={OrderType.UNKNOWN}
								hasBorder
								containsSubtotal={false}
								dataTestIdPrefix="new-order-review-order-list"
							/>
						</ReviewOrderAccountBillToCardContainer>

						{config?.lineItems?.map((item) => (
							<ReviewOrderListLineItemContainer key={item?.lineItemNumber}>
								<EstimatesLineItemGrid
									mainRowContent={[
										<Header
											description={item.description ?? UnavailableDataPlaceholderText}
											key={config.configurationId}
											header={item.sku ?? UnavailableDataPlaceholderText}
											lineNumber={String(item.lineItemNumber)}
										/>,
										<div key={`${String(item.lineItemNumber)}-door-style`}>
											<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-doorStyle">
												{item.doorStyle}
											</div>
										</div>,
										<div key={`${String(item.lineItemNumber)}-finish`}>
											<div>{NewOrderBuildGridFinishColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-finish">
												{item.finish}
											</div>
										</div>,
										<div key={`${item.lineItemNumber}-qty`}>
											<div>{NewOrderBuildGridQuantityColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-quantity">
												{item.quantityOrdered}
											</div>
										</div>,
										<div key={`${String(item.lineItemNumber)}-list-price`}>
											<div>{NewOrderViewPriceEstimateListPriceColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-listPrice">
												{item?.listPrice?.toLocaleString("en-US", {
													style: "currency",
													currency: "USD"
												})}
											</div>
										</div>,
										<div key={`${String(item.lineItemNumber)}-extended-price`}>
											<div>{NewOrderViewPriceEstimateExtendedPriceColumnHeader}</div>
											<div data-testid="new-order-review-order-list-line-item-extendedPrice">
												{item?.extendedPrice?.toLocaleString("en-US", {
													style: "currency",
													currency: "USD"
												})}
											</div>
										</div>
									]}
									modificationRows={item.modifications?.map((modification) => (
										<Modification
											description={modification.description ?? undefined}
											key={modification.userCode}
											extendedPrice={modification.extendedPrice}
											lineNumber={modification.lineNumber}
											listPrice={modification.listPrice}
											title={modification.userCode ?? undefined}
										/>
									))}
								/>
							</ReviewOrderListLineItemContainer>
						))}

						<ReviewOrderSubtotal>
							<span>{NewOrderSubtotalLabel}</span>
							<span data-testid="new-order-review-order-list-extendedPrice-configuration">
								{config.priceEstimateConfig?.pricing.extendedPrice.toLocaleString("en-US", {
									style: "currency",
									currency: "USD"
								})}
							</span>
						</ReviewOrderSubtotal>
					</>
				))}
			</StaticComponentBackground>
			<EstimatedSubtotal
				data-testid="new-order-review-order-list-extendedPrice-full-order"
				subtotal={priceEstimates?.pricing.extendedPrice ?? 0}
			/>
		</>
	);
};

export default ReviewOrderList;
