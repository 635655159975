import { useEffect, useMemo, useState } from "react";
import { LinearProgress, Modal, Typography, Button } from "@mui/material";
import Dragdrop from "components/Dragdrop/Dragdrop";
import {
	MaxUploadsError,
	MaxFileSize,
	PerImage,
	SupportedFiles,
	BrowseFiles,
	MaxFileSizeHeader,
	DragFile,
	SelectThreeImagesText,
	SupportedFilesHeader,
	AttachFile,
	FileTooLargeText,
	FileIncorrectTypeText,
	FileDuplicateText,
	FileSuccessText,
	AttachingText,
	FileTooSmallText
} from "constants/text";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import UploadFile from "@mui/icons-material/UploadFile";
import { formatBytes, isValidFile } from "utils/file";
import acceptedReplacementFileUploadTypes from "constants/acceptedReplacementFileUploadTypes";
import { Part, ReplacementCartItem, ReplacementFile } from "features/cart";
import {
	MaxFilesAlert,
	ReplacementAttachmentView,
	ReplacementUploadStatusText
} from "components/ReplacementUploadModal/replacementUploadModal.styles";

export type ErrorableFileState = {
	error: string;
	file: File;
};

export interface Props {
	open: boolean;
	isMobile: boolean;
	styles: any;
	handleClose: (files: ErrorableFileState[], item: ReplacementCartItem, part: Part | null) => void;
	item: ReplacementCartItem;
	part?: Part;
	maxFiles: number;
	fileUploads?: ReplacementFile[];
}

export const processFileUploadErrorStates = (
	file: File,
	maxMB: number,
	fileUploads: ReplacementFile[] = []
): string | undefined => {
	if (
		fileUploads.length > 0 &&
		fileUploads.find((selectedFile: ReplacementFile) => selectedFile.name === file.name)
	) {
		return FileDuplicateText;
	}

	if (file.size > maxMB * (1024 * 1024)) {
		return FileTooLargeText;
	}

	if (!acceptedReplacementFileUploadTypes.includes(file.type)) {
		return FileIncorrectTypeText;
	}

	if (file.size === 0) {
		return FileTooSmallText;
	}

	return undefined;
};

const ReplacementUploadModal = ({
	handleClose,
	item,
	part,
	styles,
	isMobile,
	open,
	maxFiles,
	fileUploads = []
}: Props) => {
	const [files, setFiles] = useState<ErrorableFileState[]>([]);
	const [error, setError] = useState<string | undefined>();

	useEffect(() => {
		setError(undefined);
		setFiles([]);
	}, [open]);

	const updateFiles = (selectedFiles: Array<File>) => {
		const filteredSelectedFiles = selectedFiles.filter((file: File) => isValidFile(file, fileUploads));
		if (filteredSelectedFiles.length > maxFiles) {
			setError(MaxUploadsError);
			return;
		}

		const erroredFiles = selectedFiles.reduce(
			(accum: any, file: File) => {
				const error = processFileUploadErrorStates(file, 10, fileUploads);

				const fileNameParts = file.name.split(".");
				if (fileNameParts.pop() === "jfif") {
					const blob = file.slice(0, file.size, file.type);
					file = new File([blob], [...fileNameParts, "jpeg"].join("."), { type: file.type });
				}

				return {
					hasErrors: [!!error, accum.hasErrors].some(Boolean),
					files: [
						...accum.files,
						{
							file: file,
							error: error
						}
					]
				};
			},
			{ hasErrors: false, files: [] }
		);

		if (!erroredFiles.hasErrors) {
			handleClose(files.concat(erroredFiles.files), item, part ?? null);
			return;
		}

		setFiles((prev) => [...prev, ...erroredFiles.files]);
	};

	const onClose = () => {
		handleClose(
			files.filter((x) => x.error === undefined),
			item,
			part ?? null
		);
	};

	const showDragDrop = useMemo(() => {
		const filteredFiles = files.filter((file: ErrorableFileState) => !file.error);
		return filteredFiles.length < maxFiles;
	}, [maxFiles, files]);

	return (
		<Modal
			open={open}
			onClose={onClose}
			data-testid="replacement-upload-file-modal"
		>
			<div className={styles.imageUploadModal}>
				<div className={styles.imageUploadModalHeader}>
					<h6>{AttachFile}</h6>
					<button
						onClick={onClose}
						data-testid="replacement-upload-file-modal-close"
					>
						<Close />
					</button>
				</div>
				<div className={styles.imageUploadModalBody}>
					<p>{SelectThreeImagesText}</p>
					{showDragDrop && (
						<Dragdrop
							accept="image/*, .pdf"
							onFileUpload={updateFiles}
						>
							<UploadFile sx={{ width: "2.5rem", height: "2.5rem", marginBottom: "1rem" }} />
							{!isMobile && <p>{DragFile}</p>}
							<Button
								variant="outlined"
								style={{ marginTop: 0, marginBottom: "1rem" }}
							>
								{BrowseFiles}
							</Button>
							<p className={styles.imageUploadModalInfoText}>
								{SupportedFilesHeader} <span>{SupportedFiles}</span>
							</p>
							<p className={styles.imageUploadModalInfoText}>
								{MaxFileSizeHeader} <span>{MaxFileSize}</span> {PerImage}
							</p>
						</Dragdrop>
					)}
					{!!error && (
						<div style={{ marginTop: 10 }}>
							<MaxFilesAlert severity="error">
								<p>{error}</p>
								<span>
									<Close
										onClick={() => setError(undefined)}
										sx={{ width: "1.25rem", height: "1.25rem" }}
									/>
								</span>
							</MaxFilesAlert>
						</div>
					)}
					{files.length > 0 && (
						<div style={{ marginTop: "1.25rem" }}>
							<Typography
								variant="subtitle1"
								style={{ textTransform: "uppercase", marginBottom: 7, fontFamily: "Gibson Regular" }}
							>
								{AttachingText}
							</Typography>
							<div>
								{files.map(({ file, error }, index) => (
									<ReplacementAttachmentView key={file.name}>
										<div className={styles.modalFileDetails}>
											<Typography
												variant="body1"
												color={error && "var(--red-700)"}
											>
												{file.name}
											</Typography>
											<Typography
												variant="body2"
												color={error ? "var(--red-700)" : "var(--text-secondary)"}
												style={{ marginTop: "2px" }}
											>
												{formatBytes(file.size)}
											</Typography>
										</div>
										<div className={styles.modalLoaderBody}>
											<div>
												<LinearProgress
													color={error ? "error" : "primary"}
													variant="determinate"
													value={error ? 5 : 100}
												/>
											</div>
										</div>
										<div className={styles.modalFileValidation}>
											{error ? (
												<ErrorOutline
													className={styles.modalFileValidationIcon}
													style={{ color: "var(--red-700)" }}
												/>
											) : (
												<CheckCircleOutline
													className={styles.modalFileValidationIcon}
													style={{ color: "var(--cwg-blue-900)" }}
												/>
											)}
											<ReplacementUploadStatusText
												error={error}
												data-testid="replacement-upload-file-modal-status-text"
											>
												{error || FileSuccessText}
											</ReplacementUploadStatusText>
										</div>
									</ReplacementAttachmentView>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ReplacementUploadModal;
