import { Button } from "@mui/material";
import { NewOrderFlowButtons, NewOrdersContent } from "components/NewOrders/NewOrders.styles";
import ViewPriceEstimatesLineItems from "./ViewPriceEstimatesLineItems";
import { useNavigate } from "react-router-dom";
import { NewOrderBack, NewOrderContinue, NewOrderViewPriceEstimateTitle } from "components/NewOrders/constants";
import EstimatedSubtotal from "components/NewOrders/ViewPriceEstimates/EstimatedSubtotal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { createViewPriceEstimates } from "../utils/NewOrderUtils";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import NewOrdersSubheader from "../NewOrdersSubheader/NewOrdersSubheader";
import { submittingNewOrderActions } from "features/submittingNewOrder";

const ViewPriceEstimatesContent = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleBack = () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		navigate("/new-order/build-order");
	};

	const handleContinue = () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		navigate("/new-order/shipping-details");
	};

	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const priceEstimates = useMemo(() => {
		if (parsedCSV !== undefined && validatedOrderResponse !== undefined) {
			return createViewPriceEstimates(parsedCSV, validatedOrderResponse);
		}
	}, [parsedCSV, validatedOrderResponse]);

	const [searchValue, setSearchValue] = useState<string | null>("");
	const [searchOptions, setSearchOptions] = useState<string[]>([]);

	useEffect(() => {
		priceEstimates?.configurations?.forEach((config) => {
			setSearchOptions((prev) =>
				prev.concat(config.items.map((item) => `${item.userCode} - ${item.description}`))
			);
		});
	}, [priceEstimates]);

	const handleLineItemSearch = (_event: SyntheticEvent, values: string | null) => {
		const foundSearch = searchOptions.find((option) =>
			option.toUpperCase().includes((values as string).toUpperCase())
		);
		setSearchValue(foundSearch ? values : "");
	};

	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);

	return (
		<NewOrdersContent>
			<NewOrdersSubheader
				draftOrder={draftOrder}
				title={NewOrderViewPriceEstimateTitle}
				dataTestId="new-order-price-estimate-label"
				hasSearch
				handleSearch={(event, values) => handleLineItemSearch(event, (values as string) ?? "")}
				searchOptions={searchOptions}
				searchValue={searchValue ?? ""}
				autoCompleteId="new-order-price-estimate-search"
			/>
			<div data-testid="price-estimate-line-items-subtotal-container">
				{priceEstimates?.configurations?.map((priceEstimatesConfiguration) => {
					const foundSearch = priceEstimatesConfiguration.items.find((item) =>
						`${item.userCode} - ${item.description}`
							.toUpperCase()
							.includes((searchValue as string).toUpperCase())
					);
					if (priceEstimatesConfiguration && foundSearch) {
						return (
							<ViewPriceEstimatesLineItems
								priceEstimateConfiguration={priceEstimatesConfiguration}
								key={priceEstimatesConfiguration.id}
								searchValue={searchValue ?? ""}
							/>
						);
					}
					return null;
				})}
				<EstimatedSubtotal subtotal={priceEstimates?.pricing.extendedPrice ?? 0} />
			</div>
			<NewOrderFlowButtons>
				<Button
					data-testid="new-order-view-price-estimates-back-button"
					variant="text"
					onClick={handleBack}
				>
					{NewOrderBack}
				</Button>
				<Button
					data-testid="new-order-view-price-estimates-continue-button"
					onClick={handleContinue}
					variant="contained"
				>
					{NewOrderContinue}
				</Button>
			</NewOrderFlowButtons>
		</NewOrdersContent>
	);
};

export default ViewPriceEstimatesContent;
