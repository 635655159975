import { FilterType } from "../../../../OrderSearch/filterType.ts";
import { useGetDraftOrdersQuery } from "features/orderApi.ts";
import ChipDropdownMenu, { ChipDropdownMenuProps } from "../../../../OrderSearch/ChipDropdownMenu.tsx";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { Dispatch, SetStateAction, useMemo } from "react";
import { SaveDraftFilters } from "../SaveDraftTable.styles";
import { displayDraftDesigner } from "../saveDraftUtils.ts";

interface DraftDetails extends ChipDropdownMenuProps {
	dataTestId: string;
	isVisible: boolean;
	GTMIdentifier: string;
}

interface SaveDraftTableFilterProps {
	filterOptions: FilterOpts;
	setFilterOptions: Dispatch<SetStateAction<FilterOpts>>;
}

const SaveDraftTableFilters = ({ filterOptions, setFilterOptions }: SaveDraftTableFilterProps) => {
	const { data: drafts } = useGetDraftOrdersQuery({});

	const designerName: string[] | undefined = useMemo(
		() =>
			drafts
				?.map((draft) => {
					return displayDraftDesigner(draft.designerName);
				})
				.filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index)
				.sort(),
		[drafts]
	);

	const accounts: (string[] | undefined)[] | undefined = useMemo(
		() =>
			drafts?.map((draft) => {
				return draft.configurations
					?.map((config) => {
						return `${config.accountNumber} - ${config.accountName}`;
					})
					.filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index);
			}),
		[drafts]
	);

	const flattenedAccounts: (string | undefined)[] | undefined = useMemo(() => accounts?.flat().sort(), [accounts]);
	const filteredAccounts: any = useMemo(() => [...new Set(flattenedAccounts)], [flattenedAccounts]);

	const onFilterChange = (id: string, values: string[]) => {
		setFilterOptions((prev: FilterOpts) => ({
			...prev,
			[id]: values
		}));
	};

	const draftDetails: DraftDetails[] = [
		{
			selectedItems: filterOptions?.accounts,
			onChange: (v) => onFilterChange("accounts", v),
			filterType: FilterType.DRAFT_ACCOUNT,
			items: filteredAccounts ?? [],
			dataTestId: "save-draft-table-account-filter-chip",
			isSearchable: true,
			hasWarning: false,
			inactiveAccounts: [],
			activeAccounts: [],
			children: undefined,
			isVisible: true,
			GTMIdentifier: "save-draft-table-account-filter-chip"
		},
		{
			selectedItems: filterOptions?.designers,
			onChange: (v) => onFilterChange("designers", v),
			filterType: FilterType.DRAFT_DESIGNER,
			items: designerName ?? [],
			dataTestId: "save-draft-table-designer-filter-chip",
			isSearchable: true,
			hasWarning: false,
			inactiveAccounts: [],
			activeAccounts: [],
			children: undefined,
			isVisible: true,
			GTMIdentifier: "save-draft-table-designer-filter-chip"
		}
	];

	return (
		<SaveDraftFilters>
			{draftDetails?.map(
				(draft) =>
					draft.isVisible && (
						<ChipDropdownMenu
							selectedItems={draft.selectedItems}
							onChange={draft.onChange}
							filterType={draft.filterType}
							items={draft.items}
							data-testid={draft.dataTestId}
							hasWarning={draft.hasWarning}
							isSearchable={draft.isSearchable}
							key={draft.filterType}
							GTMIdentifier={draft.GTMIdentifier}
						>
							{draft.children}
						</ChipDropdownMenu>
					)
			)}
		</SaveDraftFilters>
	);
};

export default SaveDraftTableFilters;
