import { NewOrdersContentContainer, NewOrdersLayoutContainer } from "components/NewOrders/NewOrders.styles";
import BuildNewOrderLineItems from "components/NewOrders/BuildNewOrderLineItems/BuildNewOrderLineItems";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { RefObject, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";

const BuildOrderPage = () => {
	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};
	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				getContainerElement={calcHeightOfHeaderForContentTopOffset}
				draftOrder={draftOrder}
			/>
			<NewOrdersContentContainer marginTopOffset={contentOffset}>
				<BuildNewOrderLineItems draftOrder={draftOrder} />
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default BuildOrderPage;
