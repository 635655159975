import { AutoCompleteContainer, SelectionDetails } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { useGetProductLinesQuery, useLazyGetProductLineQuery } from "features/globalsApi";
import { GlobalAttributeFields } from "../forms/schema";
import { SelectDoorStyle, SelectProductLine } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useEffect, useState } from "react";
import { newOrderActions } from "features/newOrder";
import { sortGlobalByDescription } from "components/NewOrders/utils/NewOrderUtils";

interface StepOneProps {
	isUpdate?: boolean;
}

const StepOne = ({ isUpdate }: StepOneProps) => {
	const [isInitializing, setIsInitializing] = useState(true);

	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);

	const dispatch = useDispatch();

	const [trigger, { data: productLine, isLoading: isLoadingProductLine }] = useLazyGetProductLineQuery();

	const { data: productLines, isLoading: isLoadingProductLines } = useGetProductLinesQuery(undefined, {
		skip: isUpdate
	});

	const { clearErrors, control, watch, setValue } = useFormContext<GlobalAttributeFields>();

	// Fetch the productLine only once for the lifecycle

	useEffect(() => {
		if (!isUpdate) {
			return;
		}

		const productLineId = updatedGlobalAttributes?.productLineId;

		if (productLineId) {
			trigger(productLineId);
		}
	}, [isUpdate, trigger, updatedGlobalAttributes?.productLineId]);

	// Get preselected doorStyle when initializaing

	useEffect(() => {
		if (!isUpdate) {
			return;
		}

		if (isInitializing) {
			const preselectedDoorStyle = productLine?.styles?.find(
				(style) => style.id === updatedGlobalAttributes?.doorStyleId
			);

			if (preselectedDoorStyle) {
				setValue("doorStyle", preselectedDoorStyle);
				setIsInitializing(false);
			}
		}
	}, [isInitializing, isUpdate, productLine?.styles, setValue, updatedGlobalAttributes?.doorStyleId]);

	const selectedProductLine = watch("productLine");

	const doorStyleOptions = selectedProductLine?.styles ?? productLine?.styles ?? [];

	return (
		<AutoCompleteContainer>
			{isUpdate ? (
				<SelectionDetails data-testid="global-attribute-modal-selection">
					{updatedGlobalAttributes?.productLine}
				</SelectionDetails>
			) : (
				<Controller
					name="productLine"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoadingProductLine || isLoadingProductLines}
							value={value}
							disableClearable
							label={SelectProductLine}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								clearErrors("productLine");
								onChange(value);
							}}
							options={sortGlobalByDescription(productLines ?? [])}
							dataTestId="select-product-line"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}

			{(!selectedProductLine || doorStyleOptions.length !== 0) && (
				<Controller
					name="doorStyle"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							value={value}
							disableClearable
							isLoading={isLoadingProductLine || isLoadingProductLines}
							label={SelectDoorStyle}
							getOptionLabel={(option) => option?.description}
							disabled={!selectedProductLine && updatedGlobalAttributes === undefined}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated = {
										doorStyle: value.description,
										doorStyleCode: value.code,
										doorStyleId: value.id
									};

									dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
								}

								clearErrors("doorStyle");

								onChange(value);
							}}
							options={sortGlobalByDescription(doorStyleOptions)}
							dataTestId="select-door-style"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
		</AutoCompleteContainer>
	);
};

export default StepOne;
