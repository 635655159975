import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const AddItemButton = styled(Button)({
	display: "flex",
	gap: "8px",
	fontWeight: 500,
	marginBottom: 16,
	marginLeft: 16,
	padding: "6px 16px",
	verticalAlign: "middle",
	letterSpacing: ".4px",
	lineHeight: "24px",
	textTransform: "uppercase"
});

export const AddLineItemContainer = styled.div(({ theme }) => ({
	border: "1px solid",
	borderColor: theme.project.colors.primaryColors.main,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	margin: "4px 16px 24px 16px",
	padding: 16
}));

export const AddLineItemInputFormControl = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 16,
	div: {
		"&:first-child": {
			flexDirection: "column",
			flex: "130%",
			".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
				fontSize: 16
			}
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

export const AddLineItemButtonWrapper = styled.div(({ theme }) => ({
	...theme.project.typography.caption,
	display: "flex",
	justifyContent: "flex-end",
	gap: 20,
	div: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		"&:nth-of-type(1)": {
			button: {
				padding: "10px 22px"
			}
		},
		"&:nth-of-type(2)": {
			button: {
				padding: "8px 22px"
			}
		},
		"&:nth-of-type(3)": {
			button: {
				padding: "9px 22px" // padding adjusted to account for not having a border
			}
		},
		[theme.breakpoints.down("md")]: {
			div: {
				display: "none"
			}
		}
	}
}));

export const AddLineItemStandardContainer = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 16,
	div: {
		"&:first-child": {
			...theme.project.typography.subtitle.subtitle1
		}
	},
	[theme.breakpoints.down("lg")]: {
		display: "block"
	}
}));

export const AddLineItemStandardDimensions = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "baseline",
	gap: 16,
	div: {
		span: {
			"&:first-child": {
				...theme.project.typography.subtitle.subtitle2,
				textTransform: "none",
				marginRight: 8
			},
			"&:nth-child(2)": {
				...theme.project.typography.body.body2
			}
		}
	}
}));
