import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { read } from "hooks/useSession";
import { CreatePendingOrderRequest } from "data/api/v1/model/create-pending-order-request";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

const storageState = read("_submittingNewOrder");

const newOrder = storageState ? { ...storageState, newOrder: storageState?.newOrder ?? [] } : undefined;
interface NewOrderSlice {
	newOrder: CreatePendingOrderRequest | undefined;
	reviewOrderDisplay: CreatePendingOrderRequest | undefined;
	draftOrder: DraftOrderViewModel | undefined;
	draftError: any;
}

const initialState: NewOrderSlice = newOrder || {
	newOrder: undefined,
	shippingAddress: undefined,
	draftOrder: undefined,
	draftError: undefined
};

const submittingNewOrderSlice = createSlice({
	name: "submittingNewOrder",
	initialState,
	reducers: {
		submittingNewOrder: (state, action) => {
			state.newOrder = {
				...action.payload,
				tandemOrderId: state.newOrder?.tandemOrderId ?? action.payload.tandemOrderId
			};
		},
		reviewOrderDisplay: (state, action) => {
			state.reviewOrderDisplay = action.payload;
		},
		clearOrderToBeSubmitted: (state) => {
			state.newOrder = undefined;
			state.draftOrder = undefined;
			state.draftError = undefined;
			state.reviewOrderDisplay = undefined;
		},
		updateDraftOrder: (state, action) => {
			state.draftOrder = {
				...action.payload,
				draftOrderId: state.draftOrder?.draftOrderId ?? action.payload.draftOrderId,
				tandemOrderId: state.newOrder?.tandemOrderId ?? action.payload.tandemOrderId
			};
		},
		updateDraftError: (state, action) => {
			state.draftError = action.payload;
		},
		clearDraftError: (state) => {
			state.draftError = undefined;
		},
		updateDraftName: (state, action) => {
			state.draftOrder = {
				...state.draftOrder,
				draftName: action.payload
			};
		},
		updateDraftAccountNumber: (
			state,
			action: PayloadAction<{ index: number | undefined; newAccountNumber: CustomerAccountViewModel | null }>
		) => {
			const { index, newAccountNumber } = action.payload;
			if (index !== undefined && state.draftOrder?.configurations) {
				const configuration = state.draftOrder.configurations[index];
				if (configuration) {
					configuration.accountId = newAccountNumber?.accountId ?? "";
				}
			}
		},
		updateDraftBillToNumber: (
			state,
			action: PayloadAction<{
				index: number | undefined;
				newBillToNumber: BillToInfoViewModel | undefined | null;
			}>
		) => {
			const { index, newBillToNumber } = action.payload;
			if (index !== undefined && state.draftOrder?.configurations) {
				const configuration = state.draftOrder.configurations[index];
				if (configuration) {
					configuration.billToId = newBillToNumber?.billToId ?? "";
				}
			}
		}
	}
});

export const submittingNewOrderActions = submittingNewOrderSlice.actions;
export default submittingNewOrderSlice.reducer;
